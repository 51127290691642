import styled from 'styled-components';

export const PainStateElemEN = styled.div`
    height: calc(100vh - 80px);
    .fixed-content {
        height: calc(100vh - 80px);
    }
    .holder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        width: 100%;
    }
  
    @media (min-width: 600px) {
        margin: 0 60px;
        height: calc(100vh - 59px);
        .fixed-content {
            height: calc(100vh - 59px);
            align-items: flex-end;
            justify-content: flex-end;
        }
        .holder {
            justify-content: flex-end;
            align-items: flex-end;
            padding-right: 2vw;
        }
    }
    @media (min-width: 1160px) {
        .holder {
            padding-right: 10vw;
            justify-content: center;
        }
    }
    .header {
            margin-bottom: 20vh;
            line-height: 1;
            letter-spacing: 3.9px;
            width: 600px;
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 600px){
                padding: 0 20px;
                width: auto;
                margin: 0 auto;
            }
            h3, h2, .disclaimer{
                padding-left: 91px;
                @media only screen and (max-width: 600px){
                    padding-left: unset;
                }
            }
            h3 {
                text-align: left;
                font-size: 3rem;
                color: #ff5917;
                letter-spacing: 1.8px;
                padding-top: 20vh;
                line-height: 1.1;
                text-transform: initial;
                margin-bottom: 18px;
                @media only screen and (max-width: 600px){
                    font-size: 1.62rem;
                    padding-top: 18vh;
                    margin-bottom: 10px;
                } 
            }
            h2 { 
                text-transform: initial;
                text-align: left;
                font-size: 1.5rem;
                letter-spacing: 1.6px;
                line-height: 1.2;
                sup{
                    font-size: 0.9rem;
                }
                @media only screen and (max-width: 600px){
                    font-size: 1rem;
                }
            }
        }
        .disclaimer{
            .disclaimer--takeaction{
                color: #ffffff;
                letter-spacing: 0.8px;
                font-size: 0.8rem;
                text-align: right;
                font-weight: 100;
                padding-top: 15vh;
                @media only screen and (max-width: 600px){
                    text-align: center;
                    padding-top: 6vh;
                }
            }
        }
        .sub {
            padding: 0 0 17vh;
                @media (min-width: 600px) and (max-width: 1160px) {
                    padding: 0 3vw 17vh;
                    white-space: nowrap;
                }
            h4 {
                font-size: 11px;
                text-transform: initial;
                max-width: 280px;
                margin: 0 auto;
                font-weight: normal;
                font-stretch: semi-condensed;
                font-style: normal;
                line-height: 1.21;
                letter-spacing: normal;
                color: #f1f2f3;

            br {
                display: none;
                    @media (max-width: 600px) {
                    display: block;
                }
            }
            @media (min-width: 600px) and (max-width: 1160px) {
                padding-right: 1vw;
            }@media (min-width: 1160px) {
                max-width: inherit;
            }
        }
    }
`;

export const TakeActionElemEN = styled.div`
    height: calc(100vh - 59px);
    .fixed-content {
        background: rgba(0,0,0,.5);
        height: calc(100vh - 59px);
        justify-content: center;
        flex-direction: column;
    }
    @media (min-width: 600px) {
        margin: 0;
        height: calc(100vh - 80px);
        .fixed-content {
            height: calc(100vh - 80px);
            align-items: flex-end;
        }
    }
    h5 {
        color:#fff;
        font-size:16px;
        font-weight:normal;
        text-align:center;
        br{
            display:none;
        }
    }
    .holder {
        height: 100vh; 
        justify-content: center;
        display: flex;
        flex-direction: column;
        @media (min-width: 600px) {
            justify-content: center;
            max-width: 574px;
            padding-right: 3vw;
        }
        @media (min-width: 1160px) {
            padding-right: 12vw;
        }
    }
    .header {
        color: #ff5917;
        text-align: center;
        margin: 0 auto;
        padding: 4vh 6vh 0;
        font-size: 3.2rem;
        width: 600px;
        text-transform:initial;
        line-height: 1.02;
        letter-spacing: 1.7px;
        @media only screen and (max-width: 600px){
            width: auto;
            margin: 0 auto;
            font-size: 1.6rem;
            padding-top: unset;
        }
        .header__takeaction {
            display: flex;
            flex-direction:row;
            justify-content: center;
            padding-top: 7vh;
            @media only screen and (max-width: 600px){
                flex-direction:column;
                padding-top: 4vh;
            }
            .header__takeaction--img1, .header__takeaction--img2{
                display: flex;
                flex-direction: row;
                img {
                    width: 100px;
                    height: 100px;
                    @media only screen and (max-width: 600px){
                        width: 80px;
                        height: 80px;
                    }
                }
            }
            .header__takeaction--img1{
                padding-right: 4vh;
                @media only screen and (max-width: 600px){
                    padding-right: unset;
                }
            }
            .header__takeaction--img2{
                @media only screen and (max-width: 600px){
                    padding-left: unset;
                    padding-top: 2vh;
                }
            }
            .header__takeaction--content1, .header__takeaction--content2{
                text-align: justify;
                color: #ffffff;
                font-size: 1rem;
                letter-spacing: 0.2px;
                line-height: 1.2;
                padding: 29px 0 0 8px;
                @media only screen and (max-width: 600px){
                    font-size: 0.9rem;
                }
                sup{
                    font-size: 0.6rem;
                }
            }
            .header__takeaction--content2{
                padding-top: 37px;
        }
        }
        .disclaimer{
            .disclaimer--content{
                color: #ffffff;
                font-size: 1.688rem;
                letter-spacing: 0px;
                padding-top: 6vh;
                line-height: 1.2;
                font-weight: 100;
                text-align: center;
                @media only screen and (max-width: 600px){
                    padding-top: 4vh;
                    font-size: 1rem;
                }
            }
        }
    }

    //.cta-copy {
        // padding: 0 20px 5vh;
        // br {
        //     @media (min-width: 600px) {
        //         display: none;
        //     }
        // }
        // @media (min-width: 600px) {
        //     margin-top: 2vh;
        //     border-top: 1px solid rgba(255,255,255,0.62);
        //     padding: 3vh 0 0;
        //     h4 {
        //         margin: 0;
        //         font-size: 23px;
        //     }
        // }
    //}
`;
