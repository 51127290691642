/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { ComplicationElemEN } from './contentEN';
import { ComplicationElemZH } from './contentZH';
import { FixedPanel } from '../content';

export const Complications = () => {
  const { t, i18n } = useTranslation('common');

  const ComplicationElem = i18n.language === "zh" ? ComplicationElemZH : ComplicationElemEN;

  const secPos = document
    .querySelector('#section-three .complications')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;

  return (
    <ComplicationElem className={['content', 'complications'].join(' ')}>
      <FixedPanel className={['fixed-content', isVisible ? 'active' : ''].join(' ')}>
        <div className="header left-to-right">
          <div className="header_content0">
            <div
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec3.complications.header.copy1'),
              }}
            />
          </div>
        <div className="header_content1">
            <div className="header_content1_sub1"
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec3.complications.header.copy2'),
              }}
            />
            <div className={'header_list'}>
            {[1, 2].map((val) => <li
              key={val}
              dangerouslySetInnerHTML={{
                __html: t(`content.sections.sec3.complications.header.header_list.copy${val}`),
              }}
            />)}
            </div>
          
                      
          <div className ="header_content1_sub2"
            dangerouslySetInnerHTML={{
              __html: t('content.sections.sec3.complications.header.copy3'),
            }}
          />
          </div>
          <div className="header_content2">
          <div className="header_content2_sub"
            dangerouslySetInnerHTML={{
              __html: t('content.sections.sec3.complications.header.copy4'),
            }}
          />
          <div className={'list'}>
            {[1, 2, 3, 4].map((val) => <li
              key={val}
              dangerouslySetInnerHTML={{
                __html: t(`content.sections.sec3.complications.header.list.copy${val}`),
              }}
            />)}
            </div>
          </div>

        </div>
        <div className="callout-box left-to-right">
        {/*  <div
            dangerouslySetInnerHTML={{
              __html: t('content.sections.sec3.complications.calloutBox.copy1'),
            }}
          />
          */}
         
        </div>
      </FixedPanel>
    </ComplicationElem>
  );
};
