import styled from 'styled-components';

export const PainStateElemZH = styled.div`
  height: calc(100vh - 59px);
  .fixed-content {
    height: calc(100vh - 59px);
  }
  
  .holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  
  @media (min-width: 600px) {
    margin: 0;
    height: calc(100vh - 80px);

    .fixed-content {
      height: calc(100vh - 80px);
      align-items: flex-end;
      justify-content: flex-end;
      
    }
    .holder {
      padding-right: 9vw;
      justify-content: flex-end;

    }
  }

  .header {
            margin-bottom: 10vh;
            display: flex;
            flex-direction: column;
            color: #fff;
            letter-spacing: 0.8px;
            width: 500px;
            padding-top: 25px;
            
            @media only screen and (max-width: 600px) {
              margin: 0 auto;
              text-align: center;
              padding: 0 20px;
              width: auto;
            }

            .header_content0,.header_content1,.header_content2,.header_content3 {
              margin-bottom: 20px;
              font-family: 'Barlow Semi Condensed', sans-serif;
              font-size: 1.06rem;           
              text-align: left;
              
              @media only screen and (max-width: 600px) {
                margin: 0 auto;
                margin-top: 15px;
                text-align: center;
                padding-left: 20px;
                padding-right: 20px;
              }
            }
  
            .header_content1 , .header_content2,.header_content3{
              text-align-last: left;
              margin-bottom: 25px;
              @media only screen and (max-width: 600px) {
                margin-bottom: 0;
              }
            }

            @media only screen and (max-width: 600px) {
              .header_content1,
              .header_content2,
              .header_content3 {
                margin-left: 0; 
                text-align: left; 
                br {
                  display: none;
                }
              }
            }
            .header_content0 {
              padding-left: 8vh;
                  h3 {
                      font-size: 4.37rem;
                      color: #ff5917;
                      margin-bottom: 2px;             
                      padding: 0 20vh 0 0;
                      
                  @media only screen and (max-width: 600px) {
                        padding: 0 20px;
                        font-size: 2.18rem;
                        text-align: center;
                  }
                }
            }

            .header_content2{
              .header_content2--section{
                font-size: 1.75rem;
                @media only screen and (max-width: 600px) {
                  font-size: 2rem;
                  margin-bottom: 0;
                  .breakTag {
                      display: block;
                    }
                }
              }
            }

            .header_content3{
                .header_content3--section{ 
                  @media only screen and (max-width: 600px) {
                    font-size: 0.98rem;
                    margin-bottom: 0;
                  }
                }
            }

            .header_content2 ,.header_content3{
                span {
                  color: #ff5917;
                  font-size: 2.06rem;
                  @media only screen and (max-width: 600px) {
                    font-size: 2rem;
                    margin-bottom: 0;
                  }
                }
            }

            .header_content1,.header_content3{
                padding-left: 10vh;
            }  
}
`;

export const ComplicationElemZH = styled.div`
  height: calc(100vh - 59px);
  .fixed-content {
    background: rgba(0,0,0,.5);
    height: calc(100vh - 59px);
    justify-content: center;
    flex-direction: column;
   
  }

  @media (min-width: 600px) {
    margin: 0;
    height: calc(100vh - 80px);

    .fixed-content {
      height: calc(100vh - 80px);
      align-items: flex-end;
      justify-content: center;
    }

    .holder {
      min-width: 800px;
    }
  }

  .header {
            color: #fff;
            letter-spacing:0.8px;
            diaplay: flex;
            flex-direction: column;
            text-transform : none;
            width: 700px;
            text-align: left;
            font-family: 'Barlow Semi Condensed', sans-serif;
        
            @media only screen and (max-width: 600px) {
              text-align: center;
              padding: 0 20px;
              width: auto;
            }
          
            .header_content0{
                margin-top: 4vh;
                font-size: 1.09rem;
                padding-left: 18vh;
                sup{
                  font-size: 1rem;
                }
                
                @media only screen and (max-width: 600px) {
                  padding-left: 0;
                  text-align: left;
                  font-size: 1rem;
                  margin-top: 10vh;
                    br{
                      display: none;
                    }
                    .breakTagSubPage {
                      @media only screen and (max-width: 600px) {
                        display: block;
                      }
                    }
                    sup{
                      font-size: 0.62rem;
                    }
                }
                span {
                  font-family: 'Barlow Semi Condensed', sans-serif;           
                    &:nth-child(1) {
                      font-size: 2.18rem;
                      font-weight: 600;
                      @media only screen and (max-width: 600px) {
                        font-size: 2.5rem;               
                      }
                    }
                    
                    &:nth-child(3) {
                      font-size: 1.06rem;
                      color: #ff5917;
                      @media only screen and (max-width: 600px) {
                        font-size: 1rem;
                      }
                    }
                    
                    &:nth-child(4){
                      font-size: 2.18rem;
                      color: #ff5917;
                      font-weight: 600;
                      @media only screen and (max-width: 600px) {
                        font-size: 2.5rem;
                      }
                    }
                    
                    &:nth-child(5){
                      font-size: 1.06rem;
                      font-weight: 600;
                      sup{
                        font-size: 0.87rem;
                        font-weight: 100;
                      }
                      @media only screen and (max-width: 600px) {
                        font-size: 1rem;
                        font-weight: 600;
                        sup{
                          font-size: 0.62rem;
                        }
                      }
                    }
                }             
              }

              .header_content1{
                  margin-left: 35vh;
                  font-family: 'Barlow Semi Condensed', sans-serif;
                  line-height: 1;
                  padding: 5vh 6vh;
                  @media only screen and  (max-width: 600px) {
                    margin: 0;
                    padding: 6vh 0 6vh 0;
                    text-align: left;
                  }

                  .header_content1_sub1{
                    font-size: 1.37rem
                    margin-bottom: 10px;
                    font-weight: 600;
                    @media only screen and  (max-width: 600px) {
                      font-size: 1.4rem;
                      margin-bottom: 10px;
                    }
                  }

                  .header_list{
                    padding-bottom: 5px;
                    li{
                      list-style:none;
                      font-size: 1.06rem;
                      font-weight: 300;
                      @media only screen and  (max-width: 600px) {
                        font-size: 1rem;
                        br {
                          display: none;
                        }
                      }

                      &:nth-child(1) {
                        span{
                          &:nth-child(3) {
                            padding-left: 12px;
                            @media only screen and  (max-width: 600px) {
                              padding-left: 0;
                            }
                            sup{
                              font-size: 0.87rem;
                              @media only screen and  (max-width: 600px) {
                                font-size: 0.5rem;
                              }
                            }
                          }
                        }
                      }
                      &:nth-child(2) {
                        span{
                          &:nth-child(3) {
                          padding-left: 12px;
                          @media only screen and  (max-width: 600px) {
                            padding-left: 0;
                          }
                          sup{
                            font-size: 0.87rem;
                            @media only screen and  (max-width: 600px) {
                              font-size: 0.5rem;
                            }
                            }
                          }
                        }
                      }
                    }
                  }
                
                  .header_content1_sub2{
                    font-family: 'Barlow Semi Condensed', sans-serif;
                    font-size: 1.06rem;
              
                    @media only screen and  (max-width: 600px) { 
                      text-align: left; 
                      font-size: 1rem;
                    }
                  }
                }
  

                .header_content2{
                  padding-left: 28vh;
                  line-height: 1;
                  font-family: 'Barlow Semi Condensed', sans-serif;
                  @media only screen and (max-width: 600px) {
                  text-align: left;
                  padding: 0;
                }
                    .header_content2_sub{
                      span{
                        &:nth-child(1){
                          font-size: 1.37rem;
                          sup{
                            font-size: 0.81rem;
                          }
                          @media only screen and (max-width: 600px) {
                            text-align: left;
                            padding-left: 0;
                            font-size: 1.4rem;
                            sup{
                              font-size: 0.62rem;
                            }
                          }
                        } 
                      }
                    }     
                  
                .list {
                  color: #ff5917;
                  font-size: 1.37rem;
                  display: flex;
                  flex-wrap: wrap;
                  padding-left: 5px; 
                  width: 50%;
                  @media only screen and (max-width: 600px) {
                    text-align: left;
                    width: 60%;
                    font-size: 1rem;
                    padding-left: 0px;
                    line-height: 0.6;
                  }
                  li{
                    list-style:none;
                    font-size: 1.43rem;
                    font-weight: 800;
                    @media only screen and (max-width: 600px) {
                      text-align: left;
                      font-size: 1.3rem;
                    }
                    &:before{
                        content: ".";
                        margin-right: 3px;
                        font-size: 1.87rem;
                        font-weight: 800;
                    }
                    span{
                        vertical-align: sub;
                    }
            
                    &:nth-child(1),&:nth-child(3){
                      padding-right: 10px;
                    }
                    
                  }
                  span {
                    box-sizing: border-box;
                    padding: 1px;
                    box-sizing: border-box;
                    margin-left: 8px;
                    justify-content: space-between;
                  }
              }
              
    strong {
      @media (min-width: 600px) {
        display: inline;
      }

      &.mobile {
        @media (min-width: 600px) {
          font-weight: normal;
        }
      }

      &.desktop {
        @media (max-width: 600px) {
          font-weight: normal;
          display: inline;
          font-size: 1.87rem;
        }
      }

      &:first-child {
        transform: translateX(10px);
      }
    }
  }

  .callout-box {
    line-height: 24px;
    color: #fff;
    text-align: justify;
    margin: 0 auto;
    padding: 0 25px;
    text-transform: initial;

    p {
      font-size: 1rem;
      text-transform: initial;  
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: justify;

      sup {
        line-height: 0;
        font-size: 0.75rem;
      }
    }

    strong {
      color: #fff;

      sup {
        font-size: 0.75rem;
        line-height: 0;
      }
    }

    @media (min-width: 600px) {
      margin: 0;
      max-width: 800px;

      p {
        font-size: 1.25rem;
        line-height: 1.5;
        letter-spacing: 0.5px;
      }
    }
  }
`;
