/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { TakeActionElemEN } from './contentEN';
import { TakeActionElemZH } from './contentZH';
import { FixedPanel } from '../content';
import takeActionImg1 from '../../../assets/take-action-img1.png';
import takeActionImg2 from '../../../assets/take-action-img2.png';

export const TakeAction = () => {
  const { t ,i18n} = useTranslation('common');

  const TakeActionElem = i18n.language === "zh" ? TakeActionElemZH :  TakeActionElemEN;
  

  const secPos = document
    .querySelector('#section-five .take-action')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;
  
  return (
    <TakeActionElem className={ ['content', 'take-action'].join(' ') }>
      <FixedPanel className={['fixed-content', isVisible ? 'active' : ''].join(' ')}>
        <div className="holder">
          <div className="header left-to-right">
            <div className='header--heading'
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec5.takeAction.header.copy1'),
              }}
            />
            <div className='header__takeaction'>
              <div className='header__takeaction--img1'>
                <img src={takeActionImg1} alt="Maintain a healthy lifestyle"/>
                <div className='header__takeaction--content1'
                    dangerouslySetInnerHTML={{
                      __html: t('content.sections.sec5.takeAction.header.header__takeaction.copy1'),
                    }}
                />
              </div>
              <div className='header__takeaction--img2'>
                <img src={takeActionImg2} alt="Vaccination25"/>
                <div className='header__takeaction--content2'
                    dangerouslySetInnerHTML={{
                      __html: t('content.sections.sec5.takeAction.header.header__takeaction.copy2'),
                    }}
                />
              </div>
            </div>
            <div className="disclaimer">
              <div className='disclaimer--content'
                dangerouslySetInnerHTML={{
                  __html: t('content.sections.sec5.takeAction.disclaimer.copy1'),
                }}
              />
            </div>
          </div>
          
          {/* <div className="cta-copy left-to-right">
            <h4
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec5.takeAction.cta-copy'),
              }}
            />
          </div> */}
          {/* <div className="sub left-to-right">
            <h5
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec5.painState.sub.copy1'),
              }}
            />
          </div> */}
        </div>
      </FixedPanel>
    </TakeActionElem>
  );
};
