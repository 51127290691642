/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { RiskElemEN } from './contentEN';
import { RiskElemZH } from './contentZH';
import { FixedPanel } from '../content';

export const Risk = () => {
	const { t, i18n } = useTranslation('common');

	const RiskElem = i18n.language === "zh" ? RiskElemZH : RiskElemEN;

	const secPos = document
		.querySelector('#section-four .risk')
		?.getBoundingClientRect();
	const posY = secPos?.y || 0;
	const posH = secPos?.height || 0;
	const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;

	return (
		<RiskElem className={['content', 'risk'].join(' ')}>
			<FixedPanel className={['fixed-content', isVisible ? 'active' : ''].join(' ')}>
				<div className="holder">
					<div className="header left-to-right">
						<span
							dangerouslySetInnerHTML={{
								__html: t('content.sections.sec4.risk.header.copy1'),
							}}
						/>
					</div>
					<div className="callout-box left-to-right">
						<h4
							dangerouslySetInnerHTML={{
								__html: t('content.sections.sec4.risk.calloutBox.copy1'),
							}}
						/>
						<div className="box">
							<div className='box__header'>
								<div className='box__header--content'
									dangerouslySetInnerHTML={{
										__html: t('content.sections.sec4.risk.calloutBox.box.copy1'),
									}}
								/>
							</div>

							<div className={'li_content'}>
								{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => <li
									key={val}
									dangerouslySetInnerHTML={{
										__html: t(`content.sections.sec4.risk.calloutBox.box.li_content.copy${val}`),
									}}
								/>)}
							</div>
						</div>
					</div>
					<div className="disclaimer left-to-right">
						<div className='disclaimer_content--1'
							dangerouslySetInnerHTML={{
								__html: t('content.sections.sec4.risk.calloutBox.disclaimer.copy1'),
							}}
						/>
						<div className='disclaimer_content--2'
							dangerouslySetInnerHTML={{
								__html: t('content.sections.sec4.risk.calloutBox.disclaimer.copy2'),
							}}
						/>
					</div>
				</div>
			</FixedPanel>
		</RiskElem>
	);
};
