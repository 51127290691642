import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Banner = styled.section`
  display: flex;
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  background-color: #172f3c;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 17.5px;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 1.75px;
  text-align: center;
  color: #f1f2f3;
  height: 59px;
  justify-content: center;
  text-transform: uppercase;

  @media only screen and (min-width: 600px) {
    height: 80px;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 3px;
    br {
      display: none;
    }    
  }
`;

export const FooterBanner = () => {
  const { t } = useTranslation('common');

  return (
    <Banner id="banner-footer">
      <span
        dangerouslySetInnerHTML={{
          __html: t('footer.banner.copy'),
        }}>
      </span>
    </Banner>
  );
};
