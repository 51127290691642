import styled from 'styled-components';

export const PainStateElemEN = styled.div`
  height: calc(100vh - 59px);

  .fixed-content {
    height: calc(100vh - 59px);
    align-items: flex-end;
    justify-content: flex-start;
  }

  .impact-on-life1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    color: #fff;
    margin-left: 8%;
    text-transform: none;

    @media (max-width: 600px) {
        width: 100vw;
        margin-left: 0px;
        margin: 0 auto;
    }

    .sub-container1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @media (max-width: 600px) {
            width: auto;

        }

        h1 {
            font-size: 59px;
            margin: 0;
            padding: 0;
            color: #ff4b00;

            @media (max-width: 600px) {
                font-size: 39px;
            }
        }

        h2 {
            font-size: 35px;
            margin: 0;
            padding: 0;
            text-transform: none;

            @media (max-width: 600px) {
                font-size: 25px;
            }
        }
    }

    .sub-container2 {
        margin-top: 20px;
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        text-align: center;
        padding: 0px;

        @media (max-width: 600px) {
            width: auto;

        }

        .sub2-inner-cont {
            position: relative;

        }

        .cont2-copy1 {
            color: #ff4b00;
            font-size: 14px;
            font-weight: 200;
            width: 100%;
        }

        .cont2-copy3 {
            width: 100%;
            font-size: 12px;
            font-weight: 200;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 600px) {
                width: auto;
                margin-left: 20px;

            }

        }

        .corners {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 395px;

            @media (max-width: 600px) {
                width: 300px;

            }

            .cont2-copy2 {
                line-height: 1.21;
                font-size: 15px;
                background: #323839;
                text-transform: none;
                padding: 11px 20px;
                font-weight: 100;
                text-align: start;
                letter-spacing: 0.1px;
                word-spacing: 2px;

                @media (max-width: 600px) {
                    width: 300px;
                    padding: 5px 10px;
                    line-height: normal;
                    font-size: 13px;
                    letter-spacing: 0.1px;
                    word-spacing: 0px;

                }


            }

        }

        .top,
        .bottom {
            position: absolute;
            width: 50px;
            height: 50px;
            pointer-events: none;
        }

        .top {
            top: 7px;
            border-top: 10px solid #fff;
            width: 20px;
        }

        .bottom {
            bottom: 7px;
            border-bottom: 10px solid #fff;
            width: 20px;
        }

        .left {
            left: 3px;
            border-left: 10px solid #fff;
            height: 40px;
        }

        .right {
            right: 3px;
            border-right: 10px solid #fff;
            height: 40px;
        }

        @media (max-width: 600px) {
            .top {
                top: 5px;
            }

            .bottom {
                bottom: 5px;
            }

            .left {
                left: -7px;
            }

            .right {
                right: -7px;
            }
        }
    }
}
`;




export const ImpactElemEN = styled.div`
  height: calc(100vh - 59px);
  .fixed-content {
    background: rgba(0,0,0,.5);
    height: calc(100vh - 59px);
    justify-content: center;
    flex-direction: column;
  }

  @media (min-width: 600px) {
    height: calc(100vh - 80px);
    margin: 0;
    .fixed-content {
      height: calc(100vh - 80px);
      justify-content: center;
    }

    .holder {
      min-width: 800px;
      transform: translateY(54px);
    }
  }

  @media (max-width: 600px) {
    .holder {
      transform: translateY(25px);
    }
  }
  @media (max-width: 800px) {
    .holder {
      min-width: 100vw;
    }      
  }
  .impact-on-life2 {
    width: 500px;
    display: flex;
    justify-content: flex-end;
    margin-top: 150px;

    @media (max-width: 1024px) {
        justify-content: center;
        margin-top: 30px;

    }

    @media (max-width: 600px) {
        justify-content: center;
        width: 100%;
        margin-top: 30px;

    }

    @media (max-width: 400px) {
        justify-content: center;

    }

    .impact-container {
        color: #fff;
        text-align: right;
        padding: 0 15px;
        max-width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;



        @media (max-width: 420px) {
            width: 300px;

        }

        .cont-1 {
            color: #ff4b00;
            width: 400px;
            text-align: center;

        }

        .cont-2 {
            background: #323839;
            text-align: justify;
            padding: 10px 15px;

            span {
                font-weight: 100;
                font-size: 16px;

                br {
                    display: none;
                }

                display:inline;

            }

            @media (max-width: 420px) {
                padding: 5px 5px;

                span {
                    display: flex;
                    flex-wrap: wrap;
                    font-weight: 100;
                    font-size: 13px;

                    br {
                        display: none;
                    }

                    display:inline;

                }

            }

            .corners {
                display: flex;
                align-items: center;
                justify-content: center;

                .top,
                .bottom {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    pointer-events: none;
                }

                .top {
                    top: -16px;
                    border-top: 10px solid #fff;
                    width: 20px;
                }

                .bottom {
                    bottom: -16px;
                    border-bottom: 10px solid #fff;
                    width: 20px;
                }

                .left {
                    left: -20px;
                    border-left: 10px solid #fff;
                    height: 40px;
                }

                .right {
                    right: -20px;
                    border-right: 10px solid #fff;
                    height: 40px;
                }

                @media (max-width: 420px) {
                    .top {
                        top: -13px;
                    }

                    .bottom {
                        bottom: -13px;
                    }

                    .left {
                        left: -13px;
                    }

                    .right {
                        right: -13px;
                    }
                }
            }
        }

        .cont-3 {
            text-align: center;
            font-size: 13px;
            margin-top: 10px;
        }
    }
}
`;
