import { useTranslation } from 'react-i18next';
import { Section } from '../content';
import { PainState } from './painState';
import { VideoElement } from '../video';
import { TakeAction } from './takeAction';
import { Wrapper } from '../content';

export const Section5 = (playedVideos) => {
  const { t } = useTranslation('common');
  const secPos = document
    .querySelector('#section-five')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY < 0;
  return (
    <Section id="section-five" className={['section-five', isVisible ? 'active' : ''].join(' ')}>
      <VideoElement
        playedVideos={playedVideos}
        section="section-five"
        source={t('content.sections.sec5.video')}
        sourceMobile={t('content.sections.sec5.videoMobile')}
        status={isVisible}
      />
      <Wrapper>
        <PainState />
        <TakeAction />
      </Wrapper>
    </Section>
  );
};
