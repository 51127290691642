import { Footer } from './components/footer';
import { Sections } from './components/sections';
import { Menu } from './components/menu';
import GlobalStyle from './styles/global';

let sectionActive = ''

export function App() {
  return (
    <>
      <GlobalStyle />
      <Sections sectionActive={ sectionActive } />
      <Footer />
      <Menu />
    </>
  );
}
