import styled from 'styled-components';

export const PainStateElemEN = styled.div`
height: calc(100vh - 59px);
    .fixed-content {
        height: calc(100vh - 59px);
    }
  
    .holder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        @media (max-width: 600px) {
        width: 100%;
        }
    }
    @media (min-width: 600px) {
        margin: 0;
        height: calc(100vh - 80px);
    
        .fixed-content {
            height: calc(100vh - 80px);
            align-items: flex-end;
            justify-content: flex-start;
        }
        .holder {
            padding-left: 9vw;
            justify-content: flex-end;
        }
    }

    .header {
        margin-bottom: 20vh;
        @media (min-width: 600px) { 
            margin-bottom: 5vh;
        }
        h3 {
            font-size: 16px;
            text-align: center;
            padding: 14vh 0 1vh;
            @media (min-width: 600px) {
                padding: 0;
                font-size: 33px;
                margin-bottom: 20px;
            }
        }
        h2 {
            font-size: 30px;
            font-style: italic;
            line-height: 1;
            letter-spacing: 3.9px;
            @media (min-width: 600px) {
                text-align: left;
                font-size: 66px;
                margin: 0;
            br {
                display: none;
            }
        }

        span {
            position: relative;
            @media (min-width: 600px) {
                display: block;
            }
            sup {
                font-size: 12px;
                position: absolute;
                top: 10px;
                @media (max-width: 600px) {
                    top: 36px;
                }
            }
            @media (min-width: 600px) {
                display: block;
                    &:first-child {
                        transform: translateX(-30px);
                    }
                    &:last-child {
                        text-align: center;
                        transform: translateX(50px);
                    }
                }
            }
        }
    }  
    .sub {
        padding: 0 0 17vh;
            h4 {
            font-size: 11px;
            max-width: 280px;
            margin: 0 auto;
            text-transform: initial;
            font-weight: normal;
            font-stretch: semi-condensed;
            font-style: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #f1f2f3;
            br {
                display: none;
                @media (max-width: 600px) {
                    display: block;
                }
            }
            @media (min-width: 600px) {
                max-width: inherit;
                text-align: center;
            }
        }
    }
`;

export const RiskElemEN = styled.div`
    height: calc(100vh - 59px);
    .fixed-content {
        background: rgba(0,0,0,.5);
        height: calc(100vh - 59px);
        justify-content: center;
        flex-direction: column;
    }
    @media (min-width: 600px) {
        margin: 0;
        height: calc(100vh - 80px);
        .fixed-content {
            height: calc(100vh - 80px);
            align-items: center;
        }
    }
    .holder {
        height: 100vh; 
        display: flex;
        flex-direction: column;
        font-family: 'Barlow Semi Condensed', sans-serif;
        letter-spacing: 1px;
        @media (max-width: 600px) {
            justify-content: center;
        }
        @media (min-width: 600px) {
            justify-content: center;
            max-width: 900px;
            transform: translateY(28px);
        }
    }

    .header {
        color: #ffffff;
        text-align: center;
        padding: 0 20px;
        font-size: 1rem;
        letter-spacing: 0.8px;
        padding-bottom: 30px;
        font-family: 'Barlow Semi Condensed', sans-serif;
            @media only screen and (max-width: 600px){
                padding-top: 16vh;
                padding-bottom: 1vh;
                font-size: 0.8rem;
            }
        }

    .callout-box {
        @media only screen and (max-width: 600px){
            padding-left: 14px;
            padding-right: 14px;
        }
        h4 {
            text-align: center;
            margin: 0 auto;
            width: auto;
            display: block;
            text-transform: unset;
            font-size: 1.8rem; 
            letter-spacing: 0.8px;
            line-height: 1.6;
            span {
                &:nth-child(1),
                &:nth-child(3){
                    color: #ff5917;
                }
            }
            sup {
                font-size: 0.6rem;
                line-height: 0;
            }
            @media only screen and (max-width: 600px) {
                padding: 3vh 0 0;
                font-size: 1.1rem;
                line-height: 1.2;
                br {
                    display: none;
                }
            }
        }
        .box {
			background: rgba(230, 220, 220, 0.12);
			margin: 25px auto 0;
			width: 338px;
			display: flex;
			flex-direction: column;
			font-family: 'Barlow Semi Condensed', sans-serif;
            @media only screen and (max-width: 600px){
                width: auto;
            }
			@media (min-width: 600px) {
				display: flex;
				align-items: center;
				padding: 18px 15px 15px 15px;
				width: 600px;
				font-family: 'Barlow Semi Condensed', sans-serif;
			}
			.box__header {
				.box__header--content{
					color: #ff5917;
					align-items: center;
					font-size: 1.8rem;
                    letter-spacing: 0.8px;
					@media only screen and (max-width: 600px){
						font-size: 1.1rem;
						padding: 10px 10px 0px 10px;
					}
				}  
			}
            .li_content{
				color:#ffffff;
				font-size : 1rem;
				display:flex;
				flex-wrap:wrap;
				color:white;
				align-items:center;
				justify-content:center;
				gap:10px;
				padding: 15px 25px 22px ;
                letter-spacing: 0.8px;
                line-height: 0.9;
                @media only screen and (max-width: 600px){
                    font-size: 0.6rem;
                    gap: 10px;
                    line-height: 0.3px;
                    padding: 10px 25px 20px;
                }
					li{
						list-style:none;
						&:before{
							content: ".";
							margin-right: 5px;
							font-size: 1.06rem;
							font-weight: 800;
						}
						span{
							vertical-align: sub;
						}
					}
				}
			}
		}
	}
    .disclaimer {
        text-align: center;
        margin-top:20px;
        color: #ffffff;
        padding: 10px 6px 30px 10px;
        @media only screen and (min-width: 600px){
            padding: 10px 20px 30px 20px;
        }
        @media only screen and (max-width: 600px){
            margin-top: 0;
        }
        div.disclaimer_content--2 {
            font-size: 0.8rem;
            padding-top: 5px;
            font-weight: 100;
            letter-spacing: 0.8px;
            @media only screen and (max-width: 600px){
                font-size: 0.5rem;
            }
        }
        div.disclaimer_content--1 {
        font-size: 1rem;
        letter-spacing: 0.8px;
        text-transform: initial;
        margin: 0 auto;
        width: auto;
        @media only screen and (max-width: 600px){
            font-size: 0.8rem;
        }
            sup {
                line-height: 0;
                font-size: 9px;
            }
        }
    }
`;
