import styled from 'styled-components';

export const IntroElemZH = styled.div`
@media (min-width: 600px) {
  margin: 0;
}
.info-container {
   width:100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-right:100px;
  margin-top:40px;
}

h4 {
   text-align: center;
   width: 440px;
   margin: 10px 0px;
   color: #fbfdff;
   font-family: 'Barlow Semi Condensed', sans-serif;
}
 }
 li{
   margin-top:5px;
   margin-bottom:5px;
   text-align: center;
   color: #d5dbe1;
   font-family: 'Barlow Semi Condensed', sans-serif;
   font-weight:200;
   span{
    position:relative;
    right:7px;
    top:0px;
  }
  
}
li::marker{
    font-size:12px;
  }
 .sub-li{
   width:390px;
   text-align: center;
   font-family: 'Barlow Semi Condensed', sans-serif;
 }
 h2{
   color:#ff4b00;
   font-weight:bold;
   text-align: center;
   font-size: 60px;
   margin:10px 0px;
   font-family: 'Barlow Semi Condensed', sans-serif;
   letter-spacing:normal;
 }
 h5{
   color:#cf4d17;
   text-align: center;
   width:450px;
   font-family: 'Barlow Semi Condensed', sans-serif;
   font-weight:600;
   font-size: 16px;
   font-stretch: semi-condensed;
   width:500px;
   font-family: 'Barlow Semi Condensed', sans-serif;
   font-weight:500;
   font-stretch: semi-condensed;
   font-size:17px;
   margin-top:10px;
 }
 @media (max-width: 1199px) {
  .info-container {
    width:100vh;
    right:0px;
    margin-right:0px;
    margin-top:30px;
    
    }
    li{
      width:auto;
      margin-top:5px;
      margin-bottom:5px;
      font-family: 'Barlow Semi Condensed', sans-serif;
      font-weight:200;
    }
}
 @media (max-width: 600px) {
  .info-container {
    width:100%;
    left:5px;
    top:50px;
    display:flex;
    flex-direction: column;
    }
 h2{
  
  font-weight:bold;
  text-align: left;
  font-size: 28px;
  font-family: 'Barlow Semi Condensed', sans-serif;  
 }
 h4{
  font-size:18px;
  width:320px;
 }
 li{
  width:300px;
  margin-top:20px;
  margin-bottom:5px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight:200;
}
h5{
  width:300px;
  font-size:14px;
  margin-top:2px;
  br{
    display:none;
  }
}

}
`;
