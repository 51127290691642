import styled from 'styled-components';

export const MenuContainer = styled.div`
  .menu-active {
    opacity: 1;
    z-index: 9999;
  }
`;

export const LangIcon = styled.div` 
   position: fixed;
   top: 20px;
   right: 100px;
   z-index: 8;
   background-color:#172f3c;
   color:white;
   display:flex;
   width:50px;
   .slide{
    width:25px;
    height:25px;
    background:green;
    position:absolute;
    z-index:1;
    transition:margin-left 0.3s;
   }
   .slide.active{
     margin-left:25px;
   }
   .langCode{
     position:absolute;
     z-index:2;
     display:flex;
     justify-content: space-between;
     span{
      display:flex;
      width:25px;
      height:25px;
      align-items:center;
      justify-content: center;
      cursor:pointer;
    }
   }
  
  
`

export const MenuToggle = styled.div`
  position: fixed;
  top: 40px;
  left: 40px;
  z-index: 8;
  display: none;
  @media only screen and (min-width: 1150px) {
    display: unset;
  }
`;

export const MenuBar = styled.div`
  opacity: 0.46;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
  a{
    text-decoration:none;
    position:relative;
    width:400px;
    left:0px;
    top:-11px;
  }
  &:before {
    content: " ";
    background-color: white; //main menu bar
    width: 44px;
    height: 3px;
    display: block;
  }
  strong {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 25.5px;
    font-weight: 200;
    line-height: 26px;
    letter-spacing: 1.50px;
    color: #f1f2f3;
    margin-left: 0px;
    opacity: 0;
    position: absolute;
    left: 15px;
  }

  &:hover,
  &.active {
    &:before {
      width: 57px;
      opacity: 0.78;
    }

    strong {
      opacity: 1;
    }
  }
`;

export const MobileMenuToggle = styled.div`
  position: fixed;
  top: 21px;
  right: 65px;
  z-index: 8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 22px;
  cursor: pointer;
  @media only screen and (min-width: 1150px) {
    display: none;
  }
`;
//mobile 
export const MobileMenuBar = styled.div`
  width: 30px;
  height: 2px;
  background-color: white;
`;

export const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: all 0.3s;
  @media only screen and (max-height: 400px) {
    justify-content: center;
  }
`;

export const CloseIcon = styled.span`
  color: #f1f2f3;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.02;
  letter-spacing: 1.01px;
  cursor: pointer;

  align-self: flex-end;
  margin: 24px 33px 40px 0;

  @media only screen and (max-height: 400px) {
    position: absolute;
  }
`;

export const MobileMenuItem = styled.div`
  color: #f1f2f3;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 40.5px;
  font-weight: bold;
  line-height: 41.5px;
  letter-spacing: 1.01px;
  margin-bottom: 30px;
  cursor: pointer;
  text-decoration:none;
  text-align: center;
  @media (min-width: 600px) {
    font-size: 30px;
    line-height: 36px;
  }
  @media only screen and (max-height: 400px) {
    margin-bottom: 20px;
  }
`;

