import styled from 'styled-components';

export const PainStateElemEN = styled.div`
  height: calc(100vh - 59px);
  .fixed-content {
    height: calc(100vh - 59px);
  }
  
  .holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  
  @media (min-width: 600px) {
    margin: 0;
    height: calc(100vh - 80px);

    .fixed-content {
      height: calc(100vh - 80px);
      align-items: flex-end;
      justify-content: flex-end;
    }
    .holder {
      padding-right: 9vw;
      justify-content: flex-end;

    }
  }

  .header {
            color: #fff;
            display: flex;
            flex-direction: column;
            font-size: 0.87rem;
            margin-bottom: 10vh;
            width: 450px;
            text-transform: none;
            @media only screen and (max-width: 600px) {
              margin: 0 auto;
              text-align: center;
              padding: 0 20px;
              width: auto;
            }
  
   
            .header_content0,.header_content1,.header_content2,.header_content3 {
              margin-bottom: 20px;
              font-family: 'Barlow Semi Condensed', sans-serif;
              font-size: 0.87rem;           
              text-align: left;
              
              @media only screen and (max-width: 600px) {
                margin: 0 auto;
                margin-top: 5px;
                text-align: center;
                padding-left: 20px;
                padding-right: 20px;
              }
            }
  
            .header_content1 , .header_content2,.header_content3{
              text-align-last: left;
              margin-bottom: 25px;
            }

            @media only screen and (max-width: 600px) {
              .header_content1,
              .header_content2,
              .header_content3 {
                margin-left: 0; 
                text-align: left; 
                br {
                  display: none;
                }
              }
            }
   
            .header_content2--section {
              .breakTag {
                @media only screen and (max-width: 600px) {
                  display: block;
                }
              }
            }
   
            .header_content0 {
                  h3 {
                      font-size: 2.81rem;
                      color: #ff5917;
                      padding: 1vh 46vh 0 0vh;
                      margin-bottom: 3px;
                  
                      @media only screen and  (max-width: 600px) {
                        padding: 14px 75px 0 0;
                        margin-bottom: 12px;
                        font-size: 2.12rem;
                        text-align: center;
                      }
                  }
            }
   
            .header_content2{
                  .header_content2--section{
                    font-size: 1.56rem;
                    @media only screen and (max-width: 600px) {
                      font-size: 0.93rem;
                      margin-bottom: 0;
                    }
                  }
            }

            .header_content3{
                .header_content3--section{ 
                  @media only screen and (max-width: 600px) {
                    font-size: 0.93rem;
                    margin-bottom: 0;
                  }
                }
              }
   
            .header_content2 ,.header_content3{
                span {
                  color: #ff5917;
                  font-size: 1.56rem;
                  @media only screen and (min-width: 300px) and (max-width: 600px) {
                    font-size: 1.25rem;
                    margin-bottom: 0;
                  }
                }
            }
 }
`;

export const ComplicationElemEN = styled.div`
  height: calc(100vh - 59px);
  .fixed-content {
    background: rgba(0,0,0,.5);
    height: calc(100vh - 59px);
    justify-content: center;
    flex-direction: column;
  }

  @media (min-width: 600px) {
    margin: 0;
    height: calc(100vh - 80px);

    .fixed-content {
      height: calc(100vh - 80px);
      align-items: flex-end;
      justify-content: center;
    }

    .holder {
      min-width: 800px;
    }
  }

  .header {
            color: #fff;
            letter-spacing:0.8px;
            diaplay: flex;
            flex-direction: column;
            text-transform : none;
            width: 700px;
            text-align: left;
            font-family: 'Barlow Semi Condensed', sans-serif;
        
            @media only screen and (max-width: 600px) {
              text-align: center;
              padding: 0 20px;
              width: auto;
            }
     
            .header_content0{
                margin-top: 4vh;
                font-size: 1.06rem;
                padding-left: 18vh;
                sup{
                  font-size: 1rem;
                }                     
                @media only screen and (max-width: 600px) {
                    padding-left: 0;
                    text-align: left;
                    font-size: 0.75rem;
                    margin-top: 10vh;
                      br{
                        display: none;
                      }
                      .breakTagSubPage {
                        @media only screen and (max-width: 600px) {
                          display: block;
                        }
                      }
                      sup{
                        font-size: 0.62rem;
                      }
                }
                span {
                  font-family: 'Barlow Semi Condensed', sans-serif;           

                    &:nth-child(1) {
                      font-size: 2.18rem;
                      font-weight: 600;
                      @media only screen and (max-width: 600px) {
                        font-size: 2rem;               
                      }
                    }
               
                    &:nth-child(3) {
                      font-size: 1.06rem;
                      color: #ff5917;
                      @media only screen and (max-width: 600px) {
                        font-size: 0.9rem;
                        font-weight: 600;
                      }
                    }
              
                    &:nth-child(4){
                      font-size: 2.18rem;
                      color: #ff5917;
                      font-weight: 600;
                      @media only screen and (max-width: 600px) {
                        font-size: 2rem;
                      }
                    }
              
                    &:nth-child(5){
                        font-size: 1.06rem;
                        font-weight: 600;
                        sup{
                          font-size: 0.75rem;
                          font-weight: 100;
                        }
                        @media only screen and (max-width: 600px) {
                          font-size: 0.9rem;
                          font-weight: 600;
                          sup{
                            font-size: 0.62rem;
                          }
                      }
                    }
                }    
          }

          .header_content1{
                margin-left: 35vh;
                font-family: 'Barlow Semi Condensed', sans-serif;
                line-height: 1;
                padding: 5vh 6vh;
                @media only screen and  (max-width: 600px) {
                  margin: 0;
                  padding: 3vh 0 3vh 0;
                  text-align: left;
                }
     
                .header_content1_sub1{
                    font-size: 1.37rem;
                    margin-bottom: 10px;
                    font-weight: 600;
                    @media only screen and  (max-width: 600px) {
                      font-size: 1.3rem;
                    }
                }
                .header_list{
                    padding-bottom: 5px;
                    li{
                        list-style:none;
                        font-size: 1.06rem;
                        font-weight: 300;
                        @media only screen and  (max-width: 600px) {
                          font-size: 0.8rem;
                          br {
                            display: none;
                          }
                        }
                        &:before{
                            content: ".";
                            margin-right: 5px;
                            font-size: 1.25rem;
                            font-weight: 800;
                            @media only screen and  (max-width: 600px) {
                              font-size: 0.62rem;
                            }
                        }
                        span{
                                vertical-align: sub;
                        }
                        &:nth-child(1) {
                          span{
                            &:nth-child(3) {
                              padding-left: 12px;
                              @media only screen and  (max-width: 600px) {
                                padding-left: 0;
                              }
                              sup{
                                font-size: 0.87rem;
                                @media only screen and  (max-width: 600px) {
                                  font-size: 0.5rem;;
                                }
                              }
                            }
                          }
                        }
                        &:nth-child(2) {
                          span{
                            &:nth-child(3) {
                              padding-left: 12px;
                              @media only screen and  (max-width: 600px) {
                                padding-left: 0;
                              }
                              sup{
                                  font-size: 0.87rem;
                                  @media only screen and  (max-width: 600px) {
                                    font-size: 0.5rem;
                                  }
                              }
                            }
                          }
                        }
                  }
              }
              .header_content1_sub2{
                font-family: 'Barlow Semi Condensed', sans-serif;
                font-size: 1.06rem;

                @media only screen and  (max-width: 600px) { 
                  text-align: left; 
                  font-size: 0.9rem;
                  br{
                    display: none;
                  }
                }
              }
          }

          .header_content2{
                  padding-left: 28vh;
                  line-height: 0.8;
                  font-family: 'Barlow Semi Condensed', sans-serif;
                  @media only screen and (max-width: 600px) {
                    text-align: left;
                    padding: 0;
                    line-height: 0.4;
                  }
                  .header_content2_sub{
                    span{
                      &:nth-child(1){
                        font-size: 1.37rem;
                        sup{
                          font-size: 0.81rem;
                        }
                        @media only screen and (max-width: 600px) {
                          text-align: left;
                          padding-left: 0;
                          font-size: 1.3rem;
                          padding-bottom: 5px;
                          sup{
                            font-size: 0.62rem;
                          }
                        }
                      } 
                    }
                  }     
      
                  .list {
                      color: #ff5917;
                      font-size: 1.37rem;
                      display: flex;
                      flex-wrap: wrap;
                      padding-left: 2px; 
                      width: 80%;
                      @media only screen and (max-width: 600px) {
                        text-align: left;
                        width: 100%;
                        font-size: 1.3rem;
                        padding-left: 0px;
                      }
                      li{
                            list-style:none;
                            font-size: 1.43rem;
                            font-weight: 800;
                            @media only screen and (max-width: 600px) {
                              text-align: left;
                              font-size: 1.08rem;
                            }
                            &:before{
                                content: ".";
                                margin-right: 3px;
                                font-size: 1.87rem;
                                font-weight: 800;
                            }
                            span{
                                vertical-align: sub;
                            }
                            &:nth-child(1),&:nth-child(3){
                              padding-right: 10px;
                            }                      
                        }
                        span {
                          box-sizing: border-box;
                          padding: 1px;
                          box-sizing: border-box;
                          margin-left: 8px;
                          justify-content: space-between;
                        }
                  }
                }
      
    strong {
      font-style: normal;

      @media (min-width: 600px) {
        display: inline;
      }

      &.mobile {
        @media (min-width: 600px) {
          font-weight: normal;
        }
      }

      &.desktop {
        @media (max-width: 600px) {
          font-weight: normal;
          display: inline;
          font-size: 1.87rem;
        }
      }

      &:first-child {
        transform: translateX(10px);
      }
    }
  }

  .callout-box {
   // line-height: 24px;
    color: #fff;

    p {
      font-size: 16px;
      //text-transform: initial;  
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: justify;
      

      sup {
        line-height: 0;
        font-size: 0.75rem;
      }
    }

    strong {
      color: #fff;

      sup {
        font-size: 0.75rem;
        line-height: 0;
      }
    }
    
    
  
      margin: 0;
     // max-width: 800px;
      text-align-last: right;
      
      span{
        &:nth-child(1){
          font-size: 1.56rem;
          margin-right: 250px;
        }
        
      }
  }
`;
