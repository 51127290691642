/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Wrapper, Background, FixedPanel } from '../content';


import ReactPlayer from "react-player";

import pcimageEn from "../../../assets/website_cover_PCimageEn.jpg"
import pcimageZh from "../../../assets/website_cover_PCimageZh.jpg"
import mobimageEn from "../../../assets/website_cover_mobimageEn.jpg"
import mobimageZh from "../../../assets/website_cover_mobimageZh.jpg"

import { HomeElemEN } from './contentEN';
import { HomeElemZH } from './contentZH';
import { ButtonElement } from '../button';


export const Homepage = () => {
  const {t,i18n} = useTranslation('common');

  const HomeElem = i18n.language === "zh" ? HomeElemZH :  HomeElemEN;
    
  const secPos = document
    .querySelector('#section-home')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = (posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0) || window.scrollY === 0;
  /*if (isVisible)
    window.localStorage.setItem('activeVideo', 'section-home')*/

  const isFixed = posY < 0;
 
  return (
    <Section id="section-home" className="section-home" >
      <Background alt="Shingles The Experience Lab" src={[i18n.language === 'zh'? pcimageZh : pcimageEn]} className={['home', isFixed ? 'fixed': '', isVisible || posY === 0 ? 'active' : '', 'desktop-img'].join(' ') } />
      <Background alt="Shingles The Experience Lab" src={[i18n.language === 'zh'? mobimageZh : mobimageEn]} className={['home', isFixed ? 'fixed': '', isVisible || posY === 0 ? 'active' : '', 'mobile-img'].join(' ') } />
      <HomeElem className={[isVisible ? 'active' : ''].join(' ')}>
        
       
        <div className={['column2 desArrow', i18n.language === 'zh'? 'display-block' : 'display-none'].join(' ')}>
        <ReactPlayer
              className="awareness-video"
              url="https://videos.gskinternet.com/pharma/shingles_com_hk/patient-Annie-1080-5.mp4"
              controls={true}
              width="200"
              height="200"
              playing={true}
              muted={true}
              loop={true}
            />
        </div>
        <div className={['column2 desArrow', i18n.language === 'zh'? 'display-none' : 'display-block'].join(' ')}>
        <ReactPlayer
              className="awareness-video"
              url="https://videos.gskinternet.com/pharma/shingles_com_hk/EN version Annie's Enduring Pain from Shingles since 2022.mp4"
              controls={true}
              width="200"
              height="200"
              playing={true}
              muted={true}
              loop={true}
            />
        </div>
        
        </HomeElem>
    </Section>
  );
};
