import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Barlow Semi Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    background-color: black;
  }

  body {
    padding-bottom: 59px;

    @media (min-width: 600px) {
      padding-bottom: 80px;
    }
  }

  em {
    font-style: normal;
  }
  
  :root {
    --background: #fff;
    --sections-text-color: #fff;
  }

  html {
      // font-size: 16px (default)
      @media (max-width: 1080px) {
          font-size: 93.75%; // 15px
      }
      @media (max-width: 720px) {
          font-size: 87.5%; // 14px
      }
  }
  
  button {
    cursor: pointer;
  }
  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  nav {
    position: fixed;
    top: 10px;
    left: 10px;
    background: black;
    padding: 0px 10px;
    z-index: 1;
  }

  nav a {
    color: white;
    font-size: 1.3em;
    line-height: 1.7;
    text-decoration: none;
  }

  nav a:hover {
    text-decoration: underline;
  }

  section {
    .video {
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transition: all 0.3s;
      object-fit: fill;

      &.active {
        opacity: 1;
      }
    }

    div {
      position: relative;
      z-index: 2;

      h1,
      h2,
      h3,
      h4,
      p {
        color: var(--sections-text-color);
        text-transform: uppercase;
        font-weight: normal;
        font-style: normal;
        font-stretch: semi-condensed;
        text-align: center;
      }

      h1 {
        font-size: 66px;
        font-weight: bold;
        line-height: 1.02;
        letter-spacing: 3.33px;
      }

      h3 {
        text-align: center;
        font-size: 35.5px;
        line-height: 1.13;
        letter-spacing: 2.84px;
      }

      h4 {
        font-size: 25.5px;
        line-height: 1.27;
        letter-spacing: 1.28px;
      }

      p {
        font-size: 20px;
        line-height: 1.23;
        letter-spacing: normal;
      }
    }
  }
`;
