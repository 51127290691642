import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  MobileMenu,
  MobileMenuToggle,
  MobileMenuBar,
  CloseIcon,
  MobileMenuItem,
  MenuContainer,
  MenuBar,
  MenuToggle,
  LangIcon
} from './content';
import closeIcon from '../../assets/close.png';

export const Menu = (props) => {
  
  const [isOpened, setOpened] = useState(false);
  const [visibleSection, setVisibleSection] = useState('section-home');
  const [menu, setMenu] = useState(null);
  const { t, i18n} = useTranslation('common');
  const [lang,setLang] =  useState(i18n.language);


  const sections = ['section-home','section-intro','section-one', 'section-two', 'section-three', 'section-four','section-five'];

  const isVisible = (section) => {
    const secPos = document.querySelector(`#${section}`)?.getBoundingClientRect();
    const posY = secPos?.y || 0;
    const posH = secPos?.height || 0;
    const isV = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;
    return isV;
  };

  const scrollIntoView = (event,id) => {
    event.preventDefault();
    setOpened(false) 
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const renderMenu = () => {
    let menu = [];

    sections.forEach((section, idx) => {
      menu = [...menu, <MenuBar    onClick={(event) => scrollIntoView(event,section)} key={idx} className={visibleSection === section ? 'active' : ''}>
          <a href="#" dtlinktype = "dtclickablelink" dtlinkname={t(`menu.${section}`)+`_${i18n.language}`}  onClick={ (event) => preventLink(event)}><strong>{t(`menu.${section}`)}</strong></a>
        </MenuBar>];
    });
    return menu;
  }
  
  window.onscroll = () => {
    setVisibleSection(null);
    sections.forEach((section) => {
      if (isVisible(section)) {
        setVisibleSection(section);
      }
    });
  };

  useEffect(() => {
    setMenu(renderMenu());
  }, [visibleSection]);

  const preventLink = (event)=>{
    event.preventDefault()
  }

 

  const ChangeLang = (langCode)=>{
     i18n.changeLanguage(langCode);
     setMenu(renderMenu());
     setLang(langCode)
  }

  return (
    <MenuContainer>
      
      <MenuToggle>
        {menu}     
      </MenuToggle>


      <LangIcon > 
          <div className ={lang === "en" ? 'slide active' :'slide'}></div>
          <div className ="langCode" dtlinktype = "dtclickablelink" dtlinkname={i18n.language}> 
            <span title = "Chinese"  onClick={()=> ChangeLang("zh")}>ZH</span>
            <span title = "English"  onClick={()=> ChangeLang("en")}>EN</span>
          </div>
      </LangIcon>

      <MobileMenuToggle onClick={() => setOpened(true)}>
        <MobileMenuBar />
        <MobileMenuBar />
        <MobileMenuBar />
      </MobileMenuToggle>

      <MobileMenu className={isOpened ? 'menu-active' : ''}>
        <CloseIcon onClick={() => setOpened(false)}>
          <img src={closeIcon} height="28" width="28" />
        </CloseIcon>

        <MobileMenuItem onClick={(event) => scrollIntoView(event,'section-home')}><a dtlinktype = "dtclickablelink" dtlinkname={t(`menu.section-home`)+`_${i18n.language}`}>{t('menu.section-home')}</a></MobileMenuItem>
        <MobileMenuItem onClick={(event) => scrollIntoView(event,'section-intro')}><a dtlinktype = "dtclickablelink" dtlinkname={t(`menu.section-intro`)+`_${i18n.language}`}>{t('menu.section-intro')}</a></MobileMenuItem>
        <MobileMenuItem onClick={(event) => scrollIntoView(event,'section-one')}><a dtlinktype = "dtclickablelink" dtlinkname={t(`menu.section-one`)+`_${i18n.language}`}>{t('menu.section-one')}</a></MobileMenuItem>
        <MobileMenuItem onClick={(event) => scrollIntoView(event,'section-two')}><a dtlinktype = "dtclickablelink" dtlinkname={t(`menu.section-two`)+`_${i18n.language}`}>{t('menu.section-two')}</a></MobileMenuItem>
        <MobileMenuItem onClick={(event) => scrollIntoView(event,'section-three')}><a dtlinktype = "dtclickablelink" dtlinkname={t(`menu.section-three`)+`_${i18n.language}`}>{t('menu.section-three')}</a></MobileMenuItem>
        <MobileMenuItem onClick={(event) => scrollIntoView(event,'section-four')}><a dtlinktype = "dtclickablelink" dtlinkname={t(`menu.section-four`)+`_${i18n.language}`}>{t('menu.section-four')}</a></MobileMenuItem>
        <MobileMenuItem onClick={(event) => scrollIntoView(event,'section-five')}><a dtlinktype = "dtclickablelink" dtlinkname={t(`menu.section-five`)+`_${i18n.language}`}>{t('menu.section-five')}</a></MobileMenuItem>
    
      </MobileMenu>
    </MenuContainer>
  );
};
