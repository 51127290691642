import styled from 'styled-components';

export const PainStateElemZH = styled.div`
height: calc(100vh - 59px);
.fixed-content {
  height: calc(100vh - 59px);
  flex-direction: column;  
  justify-content: space-between;
}

.holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

@media (min-width: 600px) {
  height: calc(100vh - 80px);
  .fixed-content {
    height: calc(100vh - 80px);
    margin: 0;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .holder {
    padding-right: 9vw;
    justify-content: flex-end;
  }
}
.pain-state {
  width: 500px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  margin-bottom: 14vh;
  line-height: 1.01;
  margin-right: 130px;

  @media (max-width: 1040px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 0vh;
      margin-top: 20vh;
      width: 100 vh;
  }
  @media (max-width: 600px) {
      justify-content: center;
      margin-left: 2vh;
      margin-top: 20vh;
      width: 100%;
  }
  .header h6 {
      text-align: center;
      color: white;
      font-size:21px;
      font-weight: 200;
      @media (max-width: 600px) {
          font-size: 20px;
          margin-right:10px;
          margin-bottom: 14px;
      }
  }
  .sub {
      width: 550px;
      display: flex;
      flex-wrap: wrap;
      color: #f1f2f3;
      align-items: center;
      justify-content: center;
      gap: 18px;
      margin-left:0px;
      @media (max-width: 600px) {
          width: 400px;
      }
      li {
          font-size: 34px;
          min-width: 130px;
          font-weight: 600;
          font-style: normal;
          letter-spacing: normal;
          color:#f1f2f3;
          text-transform: initial;
          span {
              position: relative;
              right: 6px;
              top: 5px;
          }
          @media (max-width: 600px) {
              font-size: 21px;
              gap: 0px;
              min-width: 100px;
          }
          @media (max-width: 400px) {
              font-size: 19px;
              gap: 0px;
              min-width: 90px;
          }
      }
    li::marker {
        font-size: 17px;
    }
  }
      .disclaimer {
          h6 {
              font-weight: normal;
              font-stretch: semi-condensed;
              font-style: normal;
              line-height: 1.21;
              letter-spacing: normal;
              text-align: center;
              color: #f1f2f3;
              font-size:21px;
              font-weight: 200;
              @media (max-width: 600px) {
                max-width:400px;
                width:100%;
                display:flex;
                align-items:center;
                justify-content:center;
                  br{
                    display:none  ;
                  }
          }
      }
  }

`;

export const PainSymptonsElemZH = styled.div`
 
  height: calc(100vh - 0px);
  .fixed-content {
    background: rgba(0,0,0,.5);
    height: calc(100vh - 0px);
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: 600px) {
    height: calc(100vh - 0px);
    margin: 0;
    .fixed-content {
      height: calc(100vh - 0px);
      justify-content: center;
    }

    .holder {
      min-width: 800px;
    }
  }

  .pain-symptoms {
      margin-bottom:40px;
    .pain-symptoms-cont1{ 
      text-align:center;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 29px;
      margin-right:10px;
 
      span{
        display:block;
        font-weight:200;
        font-style:normal;

      }
      @media (max-width: 600px) {
        max-width: 100%;
        margin-top:10px;
        font-size:20px;
        
        right:0%;
  
      }
    }
    @media (max-width: 600px) {
      margin-top:10px;
      margin-bottom:10px;
    }

    .pain-symptoms-cont2{
      max-width:1300px;
      color: #fff;
      position:relative;
      left:6%;
      margin-top:20px;
      @media (max-width: 600px) {
        margin-top:10px;
        br{
          display:none;
        }

      }
      .cont2-sub1{        
        font-size: 29px;
        color: #e85800;
        position:relative;
        left:13%;
        text-align:center;
        display:flex;
        @media (max-width: 600px) {
          left:0px;
        }
      }
      .cont2-sub2{
        font-family: 'Barlow Semi Condensed', sans-serif;
        text-transform:none;
        font-size: 46px;
        font-weight:500;
        position:absolute;
        right:250px;
        bottom:30px;
        color: #e85800;
        text-align:center;
        @media (max-width: 950px) {
             font-size:33px;
             right:150px;
             bottom:50px;
        }
        @media (max-width: 600px) {
          font-size: 30px;
          position:relative;
          left:-30px;
          bottom:20px;
          br{
            display:none;
          }
        }
      }
      .cont2-sub3{
        font-size: 25px;
        color: #e85800;
        position:relative;
        left:280px;
        letter-spacing: 1px;
        text-align:center;
        display:flex;
        padding-top:60px;
        @media (max-width: 600px) {
          padding-top:0px;
          left:-30px;
        }
      }
    }
    .pain-symptoms-cont3{
      color: #fff;
      text-align:center;
      position:relative;
      left:0px;
      top:50px;
      @media (max-width: 950px) {
        left:0px;
        top:10px;
      }
      @media (max-width: 600px) {
        left:0px;
        top:0px;
      }
      .cont3-sub1{         
        font-size:25px;
        font-weight:600;
        margin-top:20px;
        margin-bottom: 10px;
        @media (min-width: 600px) {
          margin-top:10px;
          margin-bottom: 0px;
        }
      }
      .cont3-sub2{
        font-size:14px;
        font-weight:normal;
        line-height:1.4;
        letter-spacing:1px;
        @media (max-width: 600px) {
          color: #fff;
        }
      }
     
    }
    .pain-symptoms-cont4{
      position:relative;
      top:80px;
      @media (max-width: 600px) {
        top:0px;
      }
      .cont4-sub1{
        font-size:13px;
        color: #fff; 
        font-weight:200;
        margin-left:50px;
      @media (max-width: 900px) {
            margin-bottom:40px;
        }
        @media (max-width: 600px) {
          margin-left:0px;
          top:0px;
          width:100%;
          display:flex;
          align-items:center;
          justify-content:center;
          color: #fff; 
        }
      }
      }
    }

  }
`;
