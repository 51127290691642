import { useEffect, useState } from 'react';
import { Homepage } from './homepage';

import { Intro } from './intro';
import { Section1 } from './section1';
import { Section2 } from './section2';
import { Section3 } from './section3';
import { Section4 } from './section4';
import { Section5 } from './section5';

import { MainElem } from './content';

export const Sections = (sectionActive) => {
  const [playedVideos, togglePlayedVideo] = useState([])
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <MainElem>
      <Homepage playedVideos={playedVideos} />
      
      <Intro playedVideos={playedVideos} />
      <Section1 playedVideos={playedVideos} />
      <Section2 playedVideos={playedVideos} />
      <Section3 playedVideos={playedVideos} />
      <Section4 playedVideos={playedVideos} />
      
      <Section5 playedVideos={playedVideos} />
    </MainElem>
  );
};
