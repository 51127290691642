/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { PainStateElemEN } from './contentEN';
import { PainStateElemZH } from './contentZH';
import { FixedPanel } from '../content';

export const PainState = () => {
   const { t,i18n } = useTranslation('common');
 
   const PainStateElem = i18n.language === "zh" ? PainStateElemZH :  PainStateElemEN;


  const secPos = document
    .querySelector('#section-three .pain-state-3-lava')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;
  
  return (
    <PainStateElem className={ ['content', 'pain-state-3-lava'].join(' ') }>
      <FixedPanel className={['fixed-content', isVisible ? 'active' : ''].join(' ')}>
        <div className="holder">
          <div className="header left-to-right"> 
            <div className ="header_content0">    
              <h3
                dangerouslySetInnerHTML={{
                  __html: t('content.sections.sec3.painState.header.copy1'),
                }}
              />
          </div>
          <div className = "header_content1">
              <div className = "header_content1--section"
                dangerouslySetInnerHTML={{
                  __html: t('content.sections.sec3.painState.header.copy2'),
                }}
              />
          </div>          
          <div className = "header_content2">
              <div className = "header_content2--section"
                dangerouslySetInnerHTML={{
                  __html: t('content.sections.sec3.painState.header.copy3'),
                }}
              />
          </div>           
          <div className = "header_content3">
            <div className = "header_content3--section"
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec3.painState.header.copy4'),
              }}
            />
          </div>
        </div>
        {/*  <div className="sub left-to-right">
            <h4
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec3.painState.sub'),
              }}
            />
            </div> */}
        </div>
      </FixedPanel>
    </PainStateElem>
  );
};
