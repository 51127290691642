import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import gskLogo from '../../assets/gsk_Logo.png';

const Copyright = styled.section`
  background-color: #f8f8f8;
  padding:4vh 3vw;   
   
  @media only screen and (min-width: 600px) {
    display:flex;
  }
  
`;

const CopyrightLogo = styled.img`
  width: 120px;
  margin: auto;
  display: block;
`;

const CopyrightCopy = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 13.5px;
  text-align: center;

  @media only screen and (min-width: 600px) {
    font-size: 0.62rem;
    padding-top:25px;
    text-align: left;
    white-space: nowrap;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.62rem;
    padding-right:20px;
    text-align: center;
    padding-right: 0vh;
    padding-top: 0vh;
    white-space: nowrap;

  }
`;

const ChineseCopyrightCopy = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 13.5px;
  text-align: center;

  @media only screen and (min-width: 600px) {
    font-size: 0.55rem;
    padding-top:23px;
    text-align: left;
    white-space: nowrap;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.62rem;
    padding-right:20px;
    text-align: center;
    padding-right: 0vh;
    padding-top: 0vh;
    white-space: nowrap;

  }
`;

const CopyrightCopyContent = styled.div`
      font-size: 0.82rem;
      padding-top: 44px;
      padding-left: 70vh;
      display: flex;
      flex-direction: row;
    
      span{
        text-decoration: underline;
        margin-right: 10px;
        a{
          color: black;
        }
      }
      @media only screen and (min-width: 768px) {
        padding-left: 17vh;
        span{
          margin-right: 10px;;
        }
      }
      @media only screen and (min-width: 1024px) {
        padding-left: 62vh;
        span{
          margin-right: 5vh;
        }
      }

      @media only screen and (max-width: 600px) {
        font-size: 0.62rem;
        padding-left:0vh;
        flex-direction: column;
        padding-right: 0vh;
        padding-top: 0;
        white-space: nowrap;
        padding: 2vh 4vh;
        text-align: center;
        span{
          margin-right: 0px;
        }
      }

`;

const ChineseCopyrightCopyContent = styled.div`
      font-size: 0.82rem;
      padding-top: 44px;
      padding-left: 70vh;
      display: flex;
      flex-direction: row;
    
      span{
        text-decoration: underline;
        margin-right: 10px;
        a{
          color: black;
        }
      }
      @media only screen and (min-width: 768px) {
        padding-left: 25vh;
        span{
          margin-right: 10px;;
        }
      }
      @media only screen and (min-width: 1024px) {
        padding-left: 73vh;
        span{
          margin-right: 5vh;
        }
      }

      @media only screen and (max-width: 600px) {
        font-size: 0.62rem;
        padding-left:0vh;
        flex-direction: column;
        padding-right: 0vh;
        padding-top: 0;
        white-space: nowrap;
        padding: 2vh 4vh;
        text-align: center;
        span{
          margin-right: 0px;
        }
      }

`;

export const FooterCopyright = () => {
  const { t , i18n } = useTranslation('common');

  let rows = [];
  for (let i = 1; i < 4; i += 1) {
    rows = [...rows, <span key={i}>{t(`footer.copyright.copy${i}`)}</span>];
  }

  const language = i18n.language;

  return (
    <Copyright>
      <picture>
        <source srcSet={gskLogo} type="image/png" />
        <CopyrightLogo src={gskLogo} alt="GSK Logo" />
      </picture>

      {language === 'zh' ?
      <ChineseCopyrightCopy>
        <span
          dangerouslySetInnerHTML={{
            __html: t('footer.copyright.copy1'),
          }}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t('footer.copyright.copy2'),
          }}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t('footer.copyright.copy3'),
          }}
        />
      </ChineseCopyrightCopy>
      :
      <CopyrightCopy>
          <span
              dangerouslySetInnerHTML={{
                __html: t('footer.copyright.copy1'),
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: t('footer.copyright.copy2'),
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: t('footer.copyright.copy3'),
              }}
            />
      </CopyrightCopy>
    }   

    {language === 'zh' ?
      <ChineseCopyrightCopyContent>
       <span dangerouslySetInnerHTML={{ __html: `<a href="https://privacy.gsk.com/en-gb/privacy-notice/general" target = "_blank" >${t('footer.copyright.copy4')}</a>`, }} />
        <span dangerouslySetInnerHTML={{ __html: `<a href="https://www.gsk.com/en-gb/terms-of-use/" target = "_blank" >${t('footer.copyright.copy5')}</a>`, }} />
        <span dangerouslySetInnerHTML={{ __html: `<a href="https://www.gsk.com/en-gb/cookie-policy/" target = "_blank" >${t('footer.copyright.copy6')}</a>`, }} />
      </ChineseCopyrightCopyContent> 
    :
      <CopyrightCopyContent>
        <span dangerouslySetInnerHTML={{ __html: `<a href="https://privacy.gsk.com/en-gb/privacy-notice/general" target = "_blank">${t('footer.copyright.copy4')}</a>`, }} />
        <span dangerouslySetInnerHTML={{ __html: `<a href="https://www.gsk.com/en-gb/terms-of-use/" target = "_blank" >${t('footer.copyright.copy5')}</a>`, }} />
        <span dangerouslySetInnerHTML={{ __html: `<a href="https://www.gsk.com/en-gb/cookie-policy/" target = "_blank" >${t('footer.copyright.copy6')}</a>`, }} />
      </CopyrightCopyContent>           
}
    </Copyright>
  );
};
