import styled from 'styled-components';

export const HomeElemZH = styled.div`
margin: 0 auto;
position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  opacity: 0;
  transition: opacity 1s;
&.active {
  opacity: 1;
  z-index: 4; 
}

@media (min-width: 600px) {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.display-block{display:block}
.display-none{display:none}
.column1 {
  top: calc(100vh - 250px);
  left: 77px;
  @media (max-width: 600px) {
    top: calc(100vh - 165px);
    left: 8px;
    width:46%;
  }
  button{
    padding: 10px 20px 10px 20px;
    
    @media (max-width: 600px) {
      padding: 10px 5px 10px 0px;
    }
    .btnText{
      letter-spacing: 2px;
      @media (max-width: 600px) {
        margin-right:5px;
        letter-spacing: 1px;
        margin-left: -3px;
      }
    }
    span{
      display:inline-block;
    }
    .arrowImg{
      margin-right: 20px;
      vertical-align:middle;
      @media (max-width: 600px) {
        margin-right: 0px;
      }
      img{
        width:100%;
        height:100%;
        @media (max-width: 600px) {
          width:45%;
        }
      }
    }
  }
}
.awareness-video {
  margin: 0 auto;
  cursor: pointer;
  padding-top: 5vh;
  @media (max-width: 599px) {
    padding-bottom: 3vh;
  }
  padding-bottom: 5vh;
  // @media (min-width: 800px) {
  //   width: 640px;
  //   height: 360px;
  // }
}
.column2 {
  top: calc(100vh - 70vh);
  right: 0;
  left: 47vw;
  width: 40vw;
  @media (max-width: 600px) {
    top: 20vh;
      right: 0px;
      width:79%;
      margin: 0 auto;
      left:0;
  }
  button{
    padding: 10px 22px 10px 60px;
    
    @media (max-width: 600px) {
      padding: 10px 0px 10px 8px;
    }
    .btnText{
      letter-spacing: 2px;
      @media (max-width: 600px) {
        margin-left:5px;
        letter-spacing: 1px;
      }
    }
    span{
      display:inline-block;
    }
    .arrowImg{
      margin-left: 20px;
      vertical-align:middle;
      @media (max-width: 600px) {
        margin-left: 0px;
      }
      img{
        width: 100%;
         height:100%;
         @media (max-width: 600px) {
          width:45%;
        }
      }
    }
  }
}

`;

