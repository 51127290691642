/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { PainStateElemEN } from './contentEN';
import { PainStateElemZH } from './contentZH';
import { FixedPanel } from '../content';

export const PainState = () => {
  const { t, i18n } = useTranslation('common');

  const PainStateElem = i18n.language === "zh" ? PainStateElemZH : PainStateElemEN;

  const secPos = document
    .querySelector('#section-one .pain-state-1-shocks')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;

  return (
    <PainStateElem className={['content', 'pain-state-1-shocks'].join(' ')}>
      <FixedPanel className={['fixed-content', isVisible ? 'active' : ''].join(' ')}>
        <div className="pain-state">
          <div className={"header"}>
            <h6
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec1.painState.header.copy1'),
              }}
            />
          </div>
          <div className={"sub"}>
            {[1, 2, 3, 4, 5, 6].map((val) => <li
              key={val}
            >
              <span dangerouslySetInnerHTML={{
                __html: t(`content.sections.sec1.painState.sub.copy${val}`),
              }}></span>
            </li>)}
          </div>
          <div className={"disclaimer"}>
            <h6
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec1.painState.disclaimer.copy1'),
              }}
            />
          </div>
        </div>
      </FixedPanel>
    </PainStateElem>
  );
};
