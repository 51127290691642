import { useTranslation } from 'react-i18next';
import { Section } from '../content';
import { PainState } from './painState';
import { PainSymptons } from './painSymptons';
import { VideoElement } from '../video';
import { Wrapper } from '../content';

export const Section1 = (playedVideos, sectionActive) => {
  const { t } = useTranslation('common');
  const secPos = document
    .querySelector('#section-one')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY <= 0 && posY + posH > 0;
  return (
    <Section id="section-one" className="section-one">
      <VideoElement
        playedVideos={playedVideos}
        section="section-one"
        source={t('content.sections.sec1.video')}
        sourceMobile={t('content.sections.sec1.videoMobile')}
        status={isVisible}
      />
      <Wrapper>
        <PainState />
        <PainSymptons />
      </Wrapper>
    </Section>
  );
};
