/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Wrapper, Background, FixedPanel } from '../content';
import { ArrowElement } from '../arrow';
import bgImg from '../../../assets/video_placeholder.png';
import bgImgMobile from '../../../assets/video_placeholder_mobile.png';
import { IntroElemEN } from './contentEN';
import { IntroElemZH } from './contentZH';

export const Intro = () => {
  const { t, i18n } = useTranslation('common');
  const IntroElem = i18n.language === "zh" ? IntroElemZH : IntroElemEN;

  const secHomePos = document
    .querySelector('#section-home')
    ?.getBoundingClientRect();
  const HomeposY = secHomePos?.y || 0;
  const HomeposH = secHomePos?.height || 0;
  const isVisibleHome = (HomeposY - (window.innerHeight / 2) <= 0 && (HomeposY - (window.innerHeight / 2)) + HomeposH > 0) || window.scrollY === 0;

  const secPos = document
    .querySelector('#section-intro')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;

  const isVisible = !isVisibleHome && ((posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0) || window.scrollY === 0);
  /*if (isVisible)
    window.localStorage.setItem('activeVideo', 'section-home')*/

  const isFixed = posY < 0;
  const preventLink = (event) => {
    event.preventDefault()
  }

  return (
    <Section id="section-intro" className="section-intro">
      <Background alt="Man with shingles rash" src={bgImg} className={[isVisible ? 'active' : '', 'desktop-img'].join(' ')} />
      <Background alt="Man with shingles rash" src={bgImgMobile} className={[isFixed ? 'fixed' : '', isVisible ? 'active' : '', 'mobile-img'].join(' ')} />
      <FixedPanel className={['intro', isVisible ? 'active' : ''].join(' ')}>
        <Wrapper className={[isVisible ? 'intro active' : ''].join(' ')}>
          <IntroElem>
            <div className='info-container'>
              <h2
                dangerouslySetInnerHTML={{
                  __html: t('content.sections.secIntro.header.copy1'),
                }}
              ></h2>
              <h4
                dangerouslySetInnerHTML={{
                  __html: t('content.sections.secIntro.header.copy2'),
                }}
              ></h4>
              <li>
                <span dangerouslySetInnerHTML={{
                  __html: t('content.sections.secIntro.header.copy3'),
                }}></span>
              </li>
              <li className='sub-li'>
                <span dangerouslySetInnerHTML={{
                  __html: t('content.sections.secIntro.header.copy4'),
                }}></span>
              </li>
              <h5
                dangerouslySetInnerHTML={{
                  __html: t('content.sections.secIntro.header.copy5'),
                }}
              >
              </h5>
            </div>
          </IntroElem>
        </Wrapper>
      </FixedPanel>
    </Section>
  );
};
