import { VideoHolder } from './content';

export const VideoElement = ({ section, source, sourceMobile }) => {
  const secPos = document
    .querySelector(`#${section}`)
    ?.getBoundingClientRect();
  const sec1Pos = document
    .querySelector(`#section-home`)
    ?.getBoundingClientRect();
  const sec2Pos = document
  .querySelector(`#section-intro`)
  ?.getBoundingClientRect();
  /*const sec3Pos = document
  .querySelector(`#section-event`)
  ?.getBoundingClientRect();
  const sec6Pos = document
  .querySelector(`#section-six`)
  ?.getBoundingClientRect();
  const posSec1Y = (sec1Pos && sec2Pos && sec3Pos)?.y || 0;*/
  const posSec1Y = sec1Pos && sec2Pos?.y || 0;
  const footerPos = document
    .querySelector(`footer`)
    ?.getBoundingClientRect();
  const posFooterY = footerPos?.y || 0;
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;
  const active = window.localStorage.getItem('activeVideo')
  if (isVisible && active !== section) {
    window.localStorage.setItem('activeVideo', section)
    const desktopVideo = document.querySelector(`#${section} video.desktop-video`);
    const mobileVideo = document.querySelector(`#${section} video.mobile-video`);
    if (desktopVideo)
      desktopVideo.play()
    if (mobileVideo)
      mobileVideo.play()
  }
  const bannerHeight = document.querySelector(`#banner-footer`)?.clientHeight;
  const isFixed = posSec1Y < 0 && (posFooterY-(window.innerHeight-bannerHeight)) > 0;

  return (
    <VideoHolder className={[isFixed ? 'fixed': '', isVisible ? 'active' : ''].join(' ') }>
      <video id="video" preload="auto" playsInline muted className="desktop-video">     
        <source src={source} />          
      </video>
      <video id="video" preload="auto" className="mobile-video" playsInline muted>     
        <source src={sourceMobile} />          
      </video>
    </VideoHolder>
  );
};
