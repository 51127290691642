/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { PainStateElemEN } from './contentEN';
import { PainStateElemZH } from './contentZH';
import { FixedPanel } from '../content';

export const PainState = () => {
  const { t, i18n } = useTranslation('common');

  const PainStateElem = i18n.language === "zh" ? PainStateElemZH : PainStateElemEN;

  const secPos = document
    .querySelector('#section-two .pain-state-2-boiling')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;

  return (
    <PainStateElem className={['content', 'pain-state-2-boiling'].join(' ')}>
      <FixedPanel className={['fixed-content', isVisible ? 'active' : ''].join(' ')}>
        <div className="impact-on-life1">
          <div className="sub-container1">
            <h1 dangerouslySetInnerHTML={{ __html: t('content.sections.sec2.painState.header.copy1') }} className="new-h1-class"></h1>
            <h2 dangerouslySetInnerHTML={{ __html: t('content.sections.sec2.painState.header.copy2') }} className="new-h2-class"></h2>
          </div>
          <div className="sub-container2">
            <div dangerouslySetInnerHTML={{ __html: t('content.sections.sec2.painState.header.copy3') }} className="cont2-copy1" />

            <div className="sub2-inner-cont">
              <div className="corners">
                <div className="top left"></div>
                <div className="bottom right"></div>

                <p dangerouslySetInnerHTML={{
                  __html: t(`content.sections.sec2.painState.header.copy4`)
                }} className="cont2-copy2"></p>
              </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: t(`content.sections.sec2.painState.header.copy5`) }} className="cont2-copy3" />
          </div>
        </div>

      </FixedPanel>
    </PainStateElem>
  );
};
