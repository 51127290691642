import styled from 'styled-components';

export const PainStateElemEN = styled.div`
  
  height: calc(100vh - 59px);
  .fixed-content {
    height: calc(100vh - 59px);
    flex-direction: column;  
    justify-content: space-between;
  }

  .holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  @media (min-width: 600px) {
    height: calc(100vh - 80px);
    .fixed-content {
      height: calc(100vh - 80px);
      margin: 0;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .holder {
      padding-right: 9vw;
      justify-content: flex-end;
    }
  }
  .pain-state {
    width: 600px;
    font-family: 'Barlow Semi Condensed', sans-serif;
    margin-bottom: 14vh;
    line-height: 1.01;

    @media (max-width: 1050px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        margin-right: 0vh;
        margin-top: 20vh;
        width: 100 vh;
    }

    @media (max-width: 600px) {
        justify-content: center;
        margin-right: 0vh;
        margin-top: 20vh;
        width: 100%;
    }

    .header h6 {
        text-align: start;
        color: #f1f2f3;
        font-size: 14px;
        font-weight: normal;

        @media (max-width: 600px) {
            font-size: 14px;
            margin-bottom: 14px;
            text-align:center;
        }
    }


    .sub {
        width: 400px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @media (max-width: 600px) {
            width: 400px;
        }

        li {
            font-size: 35px;
            margin-bottom: 10px;
            min-width: 100px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            color: #f1f2f3;
            text-transform: initial;

            span {
                position: relative;
                right: 6px;
                top: 5px;
            }

            @media (max-width: 600px) {
                min-width:80px;
                font-size: 21px;
                span {
                    position: relative;
                    right: 5px;
                    top: 0px;
                }
            }
            @media (max-width: 400px) {
                span {
                    left: 0px;
                    top: 0px;
                }
            }
        }
        li:nth-child(2) {
            margin-right: 35px;
        }

        li::marker {
            font-size: 17px;
        }
    }
    .disclaimer {
        width:400px;
        h6 {
            font-weight: normal;
            font-stretch: semi-condensed;
            font-style: normal;
            line-height: 1.21;
            letter-spacing: normal;
            text-align: center;
            color: #f1f2f3;
            font-size:14px;
            }
            @media (max-width: 600px) {
              max-width:400px;
              width:100%;
              display:flex;
              align-items:center;
              justify-content:center;
                br{
                  display:none  ;
                }
        }
    }
}
`;

export const PainSymptonsElemEN = styled.div`
  height: calc(100vh - 0px);
  .fixed-content {
    background: rgba(0,0,0,.5);
    height: calc(100vh - 0px);
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: 600px) {
    height: calc(100vh - 0px);
    margin: 0;
    .fixed-content {
      height: calc(100vh - 0px);
      justify-content: center;
    }

    .holder {
      min-width: 800px;
    }
  }

  .pain-symptoms {
    margin-top:50px;
    .pain-symptoms-cont1{
      width:100%;
      text-align:center;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      right:3.5%;
      margin-right:500px;
      span{
        display:block;
        font-weight:100;
        font-style:normal;

      }
      @media (max-width: 950px) {
        max-width: 100%;
        font-size:22px;
        right:0%;
        margin-top: 0px;
        
      }
      @media (max-width: 600px) {
        max-width: 100%;
        
        font-size:18px;
        right:0%;
      }
    }
    @media (max-width: 900px) {
      margin-top:0px;
    }
    @media (max-width: 600px) {
      margin-top:50px;
    }

    .pain-symptoms-cont2{
      max-width:1300px;
      color: #fff;
      position:relative;
      left:6%;
      margin-top:20px;
      @media (max-width: 600px) {
        margin-top:10px;
        br{
          display:none;
        }

      }
      .cont2-sub1{        
        font-size: 25px;
        color: #e85800;
        position:relative;
        bottom:10px;
        left:7%;
        text-align:center;
        display:flex;
        @media (max-width: 600px) {
          left:-10px;
          font-size:18px;
          bottom:0px;
        }
      }
      .cont2-sub2{
        font-family: 'Barlow Semi Condensed', sans-serif;
        text-transform:none;
        font-size: 38px;
        font-weight:600;
        position:absolute;
        right:200px;
        top:0%;
        color: #e85800;
        text-align:center;
        @media (max-width: 900px) {
          padding:40px 0px;
          font-size: 24px;
        }
        @media (max-width: 600px) {
          font-size: 23px;
          position:relative;
          left:-20px;
          padding:0px 0px;
          font-weight:600;
        }
      }
      .cont2-sub3{
        font-size: 25px;
        color: #e85800;
        position:relative;
        left:140px;
        letter-spacing: 1px;
        text-align:center;
        display:flex;
        padding-top:60px;
        @media (max-width: 900px) {
          top:0%;
          padding-top:70px;
          font-size: 22px;
          left:20%;
        }
        @media (max-width: 600px) {
          padding-top:0px;
          font-size: 18px;
          left:-20px;
        }
      }
    }
    .pain-symptoms-cont3{
      color: #fff;
      text-align:center;
      position:relative;
      left:10rem;
      @media (max-width: 900px) {
        left:4vh;
        bottom:3%;
      }
      @media (max-width: 600px) {
        left:0px;
      }
      .cont3-sub1{     
        font-size:25px;
        font-weight:600;
        margin-top:20px;
        margin-bottom: 10px;
        @media (max-width: 600px) {
          margin-top:15px;
          font-size:20px;
        }
      }
      .cont3-sub2{
        font-size:14px;
        font-weight:normal;
        line-height:1.4;
        letter-spacing:1px;
        @media (max-width: 600px) {
          color: #fff;
          line-height:1;
        }
      }
     
    }
    .pain-symptoms-cont4{
      .cont4-sub1{
        color: #fff; 
        font-size:13px;
        margin-left:50px;
        @media (max-width: 600px) {
          width:100%;
          margin:0px;
          margin-bottom:50px;
          display:flex;
          align-items:center;
          justify-content:center;
        }
      }
      }
    }
  }
`;
