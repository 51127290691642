/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { PainStateElemEN } from './contentEN';
import { PainStateElemZH } from './contentZH';
import { FixedPanel } from '../content';

export const PainState = () => {
  const { t , i18n} = useTranslation('common');

  const PainStateElem = i18n.language === "zh" ? PainStateElemZH :  PainStateElemEN;

  const secPos = document
    .querySelector('#section-five .pain-state-5-blowtorch')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;
  
  return (
    <PainStateElem className={ ['content', 'pain-state-5-blowtorch'].join(' ') }>
      <FixedPanel className={['fixed-content', isVisible ? 'active' : ''].join(' ')}>
        <div className="holder left-to-right">
          <div className="header">
            <h3
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec5.painState.header.copy1'),
              }}
            />
            <h2
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec5.painState.header.copy2'),
              }}
            />
            <div className="disclaimer left-to-right">
              <div className='disclaimer--takeaction'
                  dangerouslySetInnerHTML={{
                    __html: t('content.sections.sec5.painState.header.copy3'),
                  }}
                />
            </div>
          </div>
        </div>
      </FixedPanel>
    </PainStateElem>
  );
};
