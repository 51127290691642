import { useTranslation } from 'react-i18next';
import { Section } from '../content';
import { PainState } from './painState';
import { VideoElement } from '../video';
import { Impact } from './impact';
import { Wrapper } from '../content';

export const Section2 = (playedVideos) => {
  const { t } = useTranslation('common');
  const secPos = document
    .querySelector('#section-two')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;
  return (
    <Section id="section-two" className={['section-two', isVisible ? 'active' : ''].join(' ')}>
      <VideoElement
        playedVideos={playedVideos}
        section="section-two"
        source={t('content.sections.sec2.video')}
        sourceMobile={t('content.sections.sec2.videoMobile')}
        status={isVisible}
      />
      <Wrapper>
        <PainState />
        <Impact />
      </Wrapper>
    </Section>
  );
};
