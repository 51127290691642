import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const References = styled.section`
    display: flex;
    flex-direction: column;
    position: unset;
    background-color: unset;
    border: none;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 0.87rem;
    word-break: break-word;
    padding:5vh 5vw;
    padding-left:50px;
    padding-right:50px;

    @media only screen and (min-width: 600px) {
      font-size: 0.62rem;
    }
    @media only screen and (max-width: 600px) {
      font-size: 0.62rem;
      padding-left:20px;
      padding-right:20px;
    }


  .referenceAnchor {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 0.87rem;
    word-break: break-word;
    color: black;
    text-decoration: none;

    @media only screen and (min-width: 600px) {
      font-size: 0.62rem;
    }
    @media only screen and (max-width: 600px) {
      font-size: 0.62rem;
      text-align: center;
    }
  }
  .referenceAnchor:hover {
    text-decoration: underline;
  }
  .referenceAnchor:visited {
    color: gray;
  }

  @media only screen and (min-width: 600px) {
   /*  padding: 70px 10vw; */
  }
`;

const ReferencesTitle = styled.section`
 padding-bottom: 1px;
`;

const ReferencesItem = styled.b`
  @media only screen and (min-width: 600px) {
    font-weight: normal;
  }
`;

export const FooterReferences = () => {
  const { t } = useTranslation('common');

  let rows = [];
  for (let i = 1; i < 26; i += 1) {
    rows = [
      ...rows,
      <span key={i}>
        <ReferencesItem>{i}.&nbsp;</ReferencesItem>
        {i <= 9 ? ' ' : ''}
        {t(`footer.references.copy${i}.copy`)}
        &nbsp;
       <a target="_blank" className="referenceAnchor" href={t(`footer.references.copy${i}.link`)}>
          {t(`footer.references.copy${i}.link`)}
        </a> 
        {t(`footer.references.copy${i}.copy1`)}
      </span>
    ];
  }

  return (
    <References>
      <ReferencesTitle>{t('footer.references.title')}</ReferencesTitle>
      {rows}
    </References>
  );
};
