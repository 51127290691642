import styled from 'styled-components';

export const FixedPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  opacity: 0;
  transition: opacity 1s;
  
  &.intro {
    display: block;
    height: calc(100vh- 59px);
    @media (min-width: 600px) {
      height: calc(100vh - 80px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-end;
    }
  }
   
  &.home {
    display: block;
  }
  &.event {
    display: block;
    height: calc(100vh - 67vh);
    justify-content: left;
    margin-top: calc(100vh - 80vh);
    margin-left: 13vh;
    @media (min-width: 600px) {
      height: calc(100vh - 80px);
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: start;
      margin-top: calc(100vh - 52vh);
      margin-left:  calc(100vh - 85vh);
    }
  }
  &.sec6 {
    display: block;
    justify-content: left;
    margin-top: calc(100vh - 95vh);
    @media (max-width: 600px) {
      height: calc(100vh - 65vh);
    }
    @media (min-width: 600px) {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin-top: 43vh;
      margin-left: 0vh;
    }
  }

  &.active {
    opacity: 1;
    z-index: 4; 
  }
`

export const Section = styled.section`
  position: relative;
  width: 100%;

  &.section-home {
   
    height: calc(100vh - 59px);
    
    @media (min-width: 600px) {
      height: calc(100vh - 80px);
    }
  }

  &.section-intro {
    height: calc(100vh - 80px);
    @media (min-width: 600px) {
      height: calc(100vh - px);
    }
  }

  &.section-event{
    height: calc(100vh - 59px);
    @media (min-width: 600px) {
      height: calc(100vh - 80px);
    }
  }
  &.section-six{
    height: calc(100vh - 59px);
    @media (min-width: 600px) {
      height: calc(100vh - 80px);
    }
  }

  &.active {
    z-index: 2;
  }

  &#section-one {
    video:not(.fixed) {
      top: 0;
      opacity: 1;
      z-index: 2;
    }
  }
  &#section-five.active {
    video {
      opacity: 1;
    }
  }
`;

export const Background = styled.img`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  height: calc(100vh - 59px);
  width: 100vw;
  object-fit: cover;
  object-position: center;
  overflow:hidden;
  
  &.active {
    opacity: 1;
  }

  &.fixed {
    position: fixed;
    height: calc(100vh - 59px);
  }
  &.event{
    object-position: center;
   }
  &.home{
    object-position: top;
    object-fit: cover;
  }
  &.sec6{
    object-position: top;
    object-fit: cover;
  }
  @media (min-width: 600px) {
    height: calc(100vh - 80px);
  }

  &.desktop-img {
    display: none;

    @media (min-width: 600px) {
        display: block;
        
    }
  }

  &.mobile-img {
    display: block;
    @media (min-width: 600px) {
      display: none;
    }
  }
`;

export const VideoHolder = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 1;
  transform: translateZ(0);

  &.active {
    z-index: 2;
    display: block;
    animation: opacity 1s;
  }

  &.fixed {
    position: fixed;
    top: 0;
  }

  video {
    height: calc(100vh - 59px);
    width: 100vw;
    object-fit: cover;
    object-position: center;

    @media (min-width: 600px) {
      height: calc(100vh - 80px);
    }

    &.desktop-video {
      display: none;

      @media (min-width: 600px) {
          display: block;
      }
    }

    &.mobile-video {
      display: block;

      @media (min-width: 600px) {
        display: none;
      }
    }
  }

  @keyframes opacity {
    from { opacity: 0.5; }
    to   { opacity: 1; }
  }
`;

export const Item = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
`;

export const Wrapper = styled.div`
  max-width: 100vw;
  
  &.intro {
    
  }
`;

export const Arrow = styled.span`
  background: url(/arrow.png);
  width: 56px;
  height: 31px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
`

export const MainElem = styled.main`
  
`
export const Button = styled.button`
display: block;

border-radius: 50px;
color: #fff;
background-color: #505c6a;
border-color: #fff;
border-style: solid;
font-size: 18px;
text-transform: uppercase;
background-size: 40px;
max-width: 294px;
opacity: 0.7;
font-weight:bold;

@media (max-width: 600px) {
  font-size: 12px;
  width: 100%;
}
span img{
  width:30px;
}

`
export const ArrowButton = styled(Button)`
background-image: ${props => props.left ? 'url("../../assets/leftarrowIcon.PNG")':'url("../../assets/arrowIcon.PNG")'};
background-position: ${props => props.left ? 'left 25px center' :'right 25px center'};
background-repeat: no-repeat; 
`;
