/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { ImpactElemEN } from './contentEN';
import { ImpactElemZH } from './contentZH';
import { FixedPanel } from '../content';

export const Impact = () => {
  const { t, i18n } = useTranslation('common');

  const ImpactElem = i18n.language === "zh" ? ImpactElemZH : ImpactElemEN;

  const secPos = document
    .querySelector('#section-two .impact-on-life')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;

  return (
    <ImpactElem className={['content', 'impact-on-life'].join(' ')}>
      <FixedPanel className={['fixed-content', isVisible ? 'active' : ''].join(' ')}>
        <div className="impact-on-life2">
          <div className='impact-container'>
            <div className="cont-1">
              <span
                dangerouslySetInnerHTML={{
                  __html: t('content.sections.sec2.impact.header.copy1'),
                }}
              />
            </div>
            <div className="cont-2">
              <div className="corners">
                <div className="top left"></div>
                <div className="bottom right"></div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('content.sections.sec2.impact.header.copy2'),
                  }}
                />
              </div>
            </div>
            <div className="cont-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: t('content.sections.sec2.impact.header.copy3'),
                }}
              />
            </div>
          </div>
        </div>
      </FixedPanel>
    </ImpactElem>
  );
};
