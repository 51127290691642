import styled from 'styled-components';

export const HomeElemEN = styled.div`
margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  opacity: 0;
  transition: opacity 1s;
&.active {
  opacity: 1;
  z-index: 4;
  
}
  @media (min-width: 600px) {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .display-block{display:block}
  .display-none{display:none}
  .column1 {
    top: calc(100vh - 250px);
    left: 77px;
    @media (max-width: 600px) {
      top: calc(100vh - 165px);
      left: 8px;
      width:51%;
    }
    button{
      padding: 10px 5px 10px 0px;
      @media (min-width: 600px) {
        padding: 10px 10px 10px 10px;
      }
      .btnText{
        width:72%;
        @media (min-width: 600px) {
          margin-right:3px;
        }
      }
      span{
        display:inline-block;
        vertical-align:middle;
      }
      .arrowImg{
        margin-right:0px;
        
        img{
          width:100%;
          height:100%;
          @media (max-width: 600px) {
            width:45%;
          }
        }
      }
    }
  }
  .column2 {
    top: calc(100vh - 70vh);
  right: 0;
  left: 47vw;
  width: 40vw;
    @media (max-width: 600px) {
      top: 22vh;
      right: 0px;
      width:79%;
      margin: 0 auto;
      left:0;
    }
  }
  .column2 button{
    padding: 10px 0px 10px 15px;
    @media (min-width: 600px) {
      padding: 10px 5px 10px 50px;
    }
    .btnText{
      width:50%;
      @media (min-width: 600px) {
        margin-left:10px;
      }
    }
    span{
      display:inline-block;
      vertical-align:middle;
    }
    .arrowImg{
      margin-left:20px;
      @media (max-width: 600px) {
        margin-left: 0px;
      }
      img{
        width: 100%;
         height:100%;
         @media (max-width: 600px) {
          width:45%;
        }
      }
    }
  }
`;
