/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { PainSymptonsElemEN } from './contentEN';
import { PainSymptonsElemZH } from './contentZH';
import { FixedPanel } from '../content';

export const PainSymptons = () => {
  const { t,i18n } = useTranslation('common');

  const PainSymptonsElem = i18n.language === "zh" ? PainSymptonsElemZH :  PainSymptonsElemEN;

  const secPos = document
    .querySelector('#section-one .pain-symptoms')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;

  return (
    <PainSymptonsElem className={ ['content', 'pain-symptoms'].join(' ') }>
      <FixedPanel className={['fixed-content', isVisible ? 'active' : ''].join(' ')}>
        <div className={"pain-symptoms"}>
          <div className={"pain-symptoms-cont1"}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec1.painSymptons.header.copy1'),
              }}
            />
          </div>
          <div className={"pain-symptoms-cont2"}>
            <span className={"cont2-sub1"}
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec1.painSymptons.header.copy2'),
              }}
            />
            <h3 className={"cont2-sub2"}
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec1.painSymptons.header.copy3'),
              }}
            />
            <span className={"cont2-sub3"}
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec1.painSymptons.header.copy4'),
              }}
            />
          </div>
          <div className={"pain-symptoms-cont3"}>
            <div className={"cont3-sub1"}
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec1.painSymptons.header.copy5'),
              }}
            />
            <div className={"cont3-sub2"}
              dangerouslySetInnerHTML={{
                __html: t('content.sections.sec1.painSymptons.header.copy6'),
              }}
            />
          </div>
          <div className={"pain-symptoms-cont4"}>
          <div className={"cont4-sub1"}
             dangerouslySetInnerHTML={{
              __html: t('content.sections.sec1.painSymptons.header.copy7'),
            }}/>
          </div>


        </div>
      </FixedPanel>
    </PainSymptonsElem>
  );
};
