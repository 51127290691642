import { useTranslation } from 'react-i18next';
import { Section } from '../content';
import { PainState } from './painState';
import { VideoElement } from '../video';
import { Complications } from './complications';
import { Wrapper } from '../content';

export const Section3 = (playedVideos) => {
  const { t } = useTranslation('common');
  const secPos = document
    .querySelector('#section-three')
    ?.getBoundingClientRect();
  const posY = secPos?.y || 0;
  const posH = secPos?.height || 0;
  const isVisible = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;
  return (
    <Section id="section-three" className={['section-three', isVisible ? 'active' : ''].join(' ')}>
      <VideoElement
        playedVideos={playedVideos}
        section="section-three"
        source={t('content.sections.sec3.video')}
        sourceMobile={t('content.sections.sec3.videoMobile')}
        status={isVisible}
      />
      <Wrapper>
        <PainState />
        <Complications />
      </Wrapper>
    </Section>
  );
};
